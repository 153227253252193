import { DailyWord, DailyWordDatabase } from "../models";

const MILISSECONDS_IN_A_MINUTE = 60 * 1000; /*const DIAS = 86400000 * 34;*/

export function getToday(): string {
  const todayDate = new Date();
  const correctedDate = new Date(
    todayDate.valueOf() - (todayDate.getTimezoneOffset() * MILISSECONDS_IN_A_MINUTE)
  );
  return correctedDate.toISOString().split('T')[0];
}

export function getRandomDay(): string {
  const min = new Date('2022-03-06')
  const max = new Date(getToday())

  const randomDate = new Date( Math.random() * (max.valueOf() - min.valueOf()) + min.valueOf() )
  const randDate = randomDate.toISOString().split('T')[0]
  console.log(dailyWords[randDate])
  
  return randomDate.toISOString().split('T')[0];
}

export function getRandomDailyWord(): DailyWord {
  return dailyWords[getRandomDay()];
}

export function getDailyWord(): DailyWord {
  return dailyWords[getToday()];
}

export const dailyWords: DailyWordDatabase = {
  '2022-03-06': { edition: '000', date: '2022-03-06', word: 'SURGE' },
  '2022-03-07': { edition: '001', date: '2022-03-07', word: 'MARCO' },
  '2022-03-08': { edition: '002', date: '2022-03-08', word: 'PROSA' },
  '2022-03-09': { edition: '003', date: '2022-03-09', word: 'TRENO' },
  '2022-03-10': { edition: '004', date: '2022-03-10', word: 'TURVO' },
  '2022-03-11': { edition: '005', date: '2022-03-11', word: 'JUDIA' },
  '2022-03-12': { edition: '006', date: '2022-03-12', word: 'ACRES' },
  '2022-03-13': { edition: '007', date: '2022-03-13', word: 'FEITO' },
  '2022-03-14': { edition: '008', date: '2022-03-14', word: 'GOTAS' },
  '2022-03-15': { edition: '009', date: '2022-03-15', word: 'BRIGA' },
  '2022-03-16': { edition: '010', date: '2022-03-16', word: 'ATRIO', meaning: 'ÁTRIO: a sala principal, contígua à entrada das casas, no antigo mundo romano; a primeira câmara de cada lado do coração, que recebe sangue venoso; aurícula.' },
  '2022-03-17': { edition: '011', date: '2022-03-17', word: 'ESPIA', meaning: 'ESPIA: m.q. ESPIÃO (subst.); vigia, sentinela; soldado que vai à frente do exército para observar as ações do inimigo.' },
  '2022-03-18': { edition: '012', date: '2022-03-18', word: 'TEMPO' },
  '2022-03-19': { edition: '013', date: '2022-03-19', word: 'PLANO' },
  '2022-03-20': { edition: '014', date: '2022-03-20', word: 'SIGNO' },
  '2022-03-21': { edition: '015', date: '2022-03-21', word: 'VEDAR', meaning: 'VEDAR: obstruir completamente (abertura, furo, fresta).; servir de tapume ou de obstáculo (a); impedir.' },
  '2022-03-22': { edition: '016', date: '2022-03-22', word: 'TREVA' },
  '2022-03-23': { edition: '017', date: '2022-03-23', word: 'PITON', meaning: 'PÍTON: design. comum a diversas grandes serpentes constritoras do Velho Mundo, da fam. dos boídeos, esp. aquelas do gên. Python ; pitão.; serpente da fam. dos boídeos ( Python reticulatus ), com até 8,5 m de comprimento, encontrada na região indo-malaia.' },
  '2022-03-24': { edition: '018', date: '2022-03-24', word: 'BISEL', meaning: 'BISEL: corte oblíquo em aresta ou quina; chanfro, chanfradura.; borda de um objeto, talhada em viés de forma que, em vez de uma aresta ou quina em ângulo reto, se obtenha uma superfície plana oblíqua aos dois lados que formam essa borda; chanfradura, chanfro.' },
  '2022-03-25': { edition: '019', date: '2022-03-25', word: 'ALUGO' },
  '2022-03-26': { edition: '020', date: '2022-03-26', word: 'ANTES' },
  '2022-03-27': { edition: '021', date: '2022-03-27', word: 'PASTO' },
  '2022-03-28': { edition: '022', date: '2022-03-28', word: 'UNHAR', meaning: 'UNHAR: escoriar(-se), ferir(-se) ou arranhar(-se) com a(s) unha(s); agatanhar(-se).' },
  '2022-03-29': { edition: '023', date: '2022-03-29', word: 'VIRUS', meaning: 'VÍRUS: cada um de um grupo de agentes infecciosos diminutos, desprovidos de metabolismo independente, que se replicam somente no interior de células vivas hospedeiras.' },
  '2022-03-30': { edition: '024', date: '2022-03-30', word: 'CALVO', meaning: 'CALVO: diz-se de ou indivíduo em cuja cabeça (couro cabeludo), ou parte dela, não há cabelos, seja por corte, raspagem etc., seja por causas orgânicas internas (doença, envelhecimento, quimioterapia etc.).'  },
  '2022-03-31': { edition: '025', date: '2022-03-31', word: 'VISTA', meaning: 'VISTA: capacidade de perceber o mundo exterior pelos olhos; visão.' },
  '2022-04-01': { edition: '026', date: '2022-04-01', word: 'PILHA' },
  '2022-04-02': { edition: '027', date: '2022-04-02', word: 'BUZIO', meaning: 'BÚZIO: design. comum a diversos moluscos gastrópodes, providos de grandes conchas, us. como buzina; canailha. molusco gastrópode ( Cassis tuberosa ) da fam. dos cassidídeos, encontrado em águas rasas do litoral dos E.U.A. até São Paulo; de até 18 cm de comprimento e forma piramidal, mostrando na superfície uma trama de linhas em sentidos transversal e longitudinal; atapu, búzio-totó, guatapi, itapu, uatapu, vapuaçu, vatapu.' },
  '2022-04-03': { edition: '028', date: '2022-04-03', word: 'LERDA' },
  '2022-04-04': { edition: '029', date: '2022-04-04', word: 'COFRE' },
  '2022-04-05': { edition: '030', date: '2022-04-05', word: 'TUBAS', meaning: 'TUBAS: música: trombeta de metal composta de um tubo reto, longo e estreito; anatomia: estrutura ou canal em forma de tubo [Anteriormente denominada trompa. ].' },
  '2022-04-06': { edition: '031', date: '2022-04-06', word: 'TOUCA' },
  '2022-04-07': { edition: '032', date: '2022-04-07', word: 'SOVAR', meaning: 'SOVAR: misturar bem (massa), calcando com as mãos sobre uma superfície; amassar, bater. ' },
  '2022-04-08': { edition: '033', date: '2022-04-08', word: 'FASTO', meaning: 'FASTO: magnificência, pompa, ostentação.' },
  '2022-04-09': { edition: '034', date: '2022-04-09', word: 'FUNGO', meaning: 'design. comum aos organismos do reino Fungi, heterotróficos, esp. saprófagos ou parasitas, aclorofilados, uni ou pluricelulares, com parede celular de quitina, estrutura principalmente filamentosa e cuja nutrição se dá por absorção [A maioria é microscópica, mas alguns tornam-se reconhecíveis através de suas frutificações, como, p.ex., os cogumelos.].' },
  '2022-04-10': { edition: '035', date: '2022-04-10', word: 'PAIOL' },
  '2022-04-11': { edition: '036', date: '2022-04-11', word: 'FRISA', meaning: 'FRISA: tecido rústico de lã.' },
  '2022-04-12': { edition: '037', date: '2022-04-12', word: 'TREMA', meaning: 'TREMA: sinal diacrítico (¨) que, em algumas línguas, se sobrepõe a uma vogal para indicar que esta é pronunciada em sílaba separada (como no francês naïve ) ou para alterar o som de determinada vogal (como no alemão Führer ) [No português, com o Acordo Ortográfico de 1990, o trema passou a ser empr. apenas em palavras derivadas de nomes próprios estrangeiros (p.ex., hübnerita, a partir de Adolf Hübner ).].' },
  '2022-04-13': { edition: '038', date: '2022-04-13', word: 'FEUDO', meaning: 'na Idade Média, terra ou direito, renda concedidos por um senhor a um vassalo em troca de serviços.' },
  '2022-04-14': { edition: '039', date: '2022-04-14', word: 'VENUS', meaning: 'VÊNUS: segundo planeta em ordem de afastamento do Sol, com órbita entre Mercúrio e a Terra' },
  '2022-04-15': { edition: '040', date: '2022-04-15', word: 'RISTE', meaning: 'suporte de ferro que tinha por finalidade firmar o conto ("parte inferior") da lança, quando o cavaleiro estava pronto para investir.' },
  '2022-04-16': { edition: '041', date: '2022-04-16', word: 'MUNIR', meaning: 'prover de munição(ões); prover com meios de defesa; fortificar. "o rei resolveu munir o castelo"' },
  '2022-04-17': { edition: '042', date: '2022-04-17', word: 'HUMOS', meaning: 'HÚMUS: Camada superior do solo, composta em especial de matéria orgânica, maioritariamente vegetal, decomposta ou em decomposição.' },
  '2022-04-18': { edition: '043', date: '2022-04-18', word: 'LUNAR' },
  '2022-04-19': { edition: '044', date: '2022-04-19', word: 'BANHO' },
  '2022-04-20': { edition: '045', date: '2022-04-20', word: 'DOCES' },
  '2022-04-21': { edition: '046', date: '2022-04-21', word: 'GLACE', meaning: 'camada brilhante, enrijecida ou não, à base de açúcar e clara de ovo, com que se recobre ou confeita bolos e doces; glacê' },
  '2022-04-22': { edition: '047', date: '2022-04-22', word: 'PAREO' },
  '2022-04-23': { edition: '048', date: '2022-04-23', word: 'CAROS' },
  '2022-04-24': { edition: '049', date: '2022-04-24', word: 'BICHO' },
  '2022-04-25': { edition: '050', date: '2022-04-25', word: 'PUDER' },
  '2022-04-26': { edition: '051', date: '2022-04-26', word: 'PAVIO' },
  '2022-04-27': { edition: '052', date: '2022-04-27', word: 'RUNAS', meaning: 'Nome de caracteres que compunham alguns alfabetos dos mais antigos dos povos germânicos e escandinavos, e aos quais se atribuía certo poder mágico, conforme o nome indica (rún = mistério, segredo).' },
  '2022-04-28': { edition: '053', date: '2022-04-28', word: 'LISTA' },
  '2022-04-29': { edition: '054', date: '2022-04-29', word: 'MENOR' },
  '2022-04-30': { edition: '055', date: '2022-04-30', word: 'HOTEL' },
  '2022-05-01': { edition: '056', date: '2022-05-01', word: 'IGUAL' },
  '2022-05-02': { edition: '057', date: '2022-05-02', word: 'RUBLO', meaning: 'meio pelo qual são efetuadas transações monetárias na Federação Russa e no Tadjiquistão.' },
  '2022-05-03': { edition: '058', date: '2022-05-03', word: 'GOELA', meaning: '1. a parte anterior do pescoço; garganta. 2. abertura que separa a boca da faringe; fauce, garganta.' },
  '2022-05-04': { edition: '059', date: '2022-05-04', word: 'ALPES' },
  '2022-05-05': { edition: '060', date: '2022-05-05', word: 'GARIS' },
  '2022-05-06': { edition: '061', date: '2022-05-06', word: 'TENOR' },
  '2022-05-07': { edition: '062', date: '2022-05-07', word: 'ETNIA', meaning: 'coletividade de indivíduos que se diferencia por sua especificidade sociocultural, refletida principalmente na língua, religião e maneira de agir; grupo étnico.' },
  '2022-05-08': { edition: '063', date: '2022-05-08', word: 'BOTAR' },
  '2022-05-09': { edition: '064', date: '2022-05-09', word: 'CARNE' },
  '2022-05-10': { edition: '065', date: '2022-05-10', word: 'LINDA' },
  '2022-05-11': { edition: '066', date: '2022-05-11', word: 'BIFAS', meaning: 'ação de esbofetear; tapa aplicado no rosto; tabefe, bofetada.' },
  '2022-05-12': { edition: '067', date: '2022-05-12', word: 'RUINA' },
  '2022-05-13': { edition: '068', date: '2022-05-13', word: 'TRAVO', meaning: 'sabor amargo e adstringente de qualquer comida ou bebida; amargor.' },
  '2022-05-14': { edition: '069', date: '2022-05-14', word: 'PALMO', meaning: 'extensão medida entre a extremidade do dedo polegar e a do mínimo, na parte interna da mão bem aberta.' },
  '2022-05-15': { edition: '070', date: '2022-05-15', word: 'PADRE' },
  '2022-05-16': { edition: '071', date: '2022-05-16', word: 'LOUSA' },
  '2022-05-17': { edition: '072', date: '2022-05-17', word: 'VENHA' },
  '2022-05-18': { edition: '073', date: '2022-05-18', word: 'ZINCO' },
  '2022-05-19': { edition: '074', date: '2022-05-19', word: 'ESPIA' },
  '2022-05-20': { edition: '075', date: '2022-05-20', word: 'VELHO' },
  '2022-05-21': { edition: '076', date: '2022-05-21', word: 'BORDA' },
  '2022-05-22': { edition: '077', date: '2022-05-22', word: 'COTAR' },
  '2022-05-23': { edition: '078', date: '2022-05-23', word: 'BRIAL', meaning: 'espécie de túnica que o cavaleiro vestia sobre as armas, ou sobre a roupa de baixo quando desarmado.' },
  '2022-05-24': { edition: '079', date: '2022-05-24', word: 'VELAR', meaning: 'permanecer de vigia, de sentinela; vigiar, guardar.' },
  '2022-05-25': { edition: '080', date: '2022-05-25', word: 'FURTO' },
  '2022-05-26': { edition: '081', date: '2022-05-26', word: 'BRUTA' },
  '2022-05-27': { edition: '082', date: '2022-05-27', word: 'FICHA' },
  '2022-05-28': { edition: '083', date: '2022-05-28', word: 'MATOU' },
  '2022-05-29': { edition: '084', date: '2022-05-29', word: 'EXITO' },
  '2022-05-30': { edition: '085', date: '2022-05-30', word: 'LUZIR', meaning: 'dar luz; brilhar com a própria luz.' },
  '2022-05-31': { edition: '086', date: '2022-05-31', word: 'BATER' },
  '2022-06-01': { edition: '087', date: '2022-06-01', word: 'DINAR', meaning: 'moeda de ouro cunhada pelos árabes desde fins do sVII e corrente na península Ibérica no sXII; morabitino de ouro.' },
  '2022-06-02': { edition: '088', date: '2022-06-02', word: 'HIDRO' },
  '2022-06-03': { edition: '089', date: '2022-06-03', word: 'JINGA' },
  '2022-06-04': { edition: '090', date: '2022-06-04', word: 'TREMO' },
  '2022-06-05': { edition: '091', date: '2022-06-05', word: 'CRISE' },
  '2022-06-06': { edition: '092', date: '2022-06-06', word: 'BUJAO' },
  '2022-06-07': { edition: '093', date: '2022-06-07', word: 'BEIJA' },
  '2022-06-08': { edition: '094', date: '2022-06-08', word: 'DOCAS' },
  '2022-06-09': { edition: '095', date: '2022-06-09', word: 'TIADE', meaning: 'Tíade: sacerdotisa do culto de Baco; bacante.' },
  '2022-06-10': { edition: '096', date: '2022-06-10', word: 'GELOU' },
  '2022-06-11': { edition: '097', date: '2022-06-11', word: 'LUTEI' },
  '2022-06-12': { edition: '098', date: '2022-06-12', word: 'INCHA' },
  '2022-06-13': { edition: '099', date: '2022-06-13', word: 'CUIDA' },
  '2022-06-14': { edition: '100', date: '2022-06-14', word: 'TRECO' },
  '2022-06-15': { edition: '101', date: '2022-06-15', word: 'MEIAS' },
  '2022-06-16': { edition: '102', date: '2022-06-16', word: 'BAGRE' },
  '2022-06-17': { edition: '103', date: '2022-06-17', word: 'EDITA' },
  '2022-06-18': { edition: '104', date: '2022-06-18', word: 'ANTES' },
  '2022-06-19': { edition: '105', date: '2022-06-19', word: 'CREIO' },
  '2022-06-20': { edition: '106', date: '2022-06-20', word: 'MEDOS' },
  '2022-06-21': { edition: '107', date: '2022-06-21', word: 'CUIAS' },
  '2022-06-22': { edition: '108', date: '2022-06-22', word: 'ISCAR', meaning: 'pôr isca em; cevar.' },
  '2022-06-23': { edition: '109', date: '2022-06-23', word: 'COLHE' },
  '2022-06-24': { edition: '110', date: '2022-06-24', word: 'RANHO' },
  '2022-06-25': { edition: '111', date: '2022-06-25', word: 'LOTAR' },
  '2022-06-26': { edition: '112', date: '2022-06-26', word: 'DOSAR' },
  '2022-06-27': { edition: '113', date: '2022-06-27', word: 'CONTA' },
  '2022-06-28': { edition: '114', date: '2022-06-28', word: 'MOITA' },
  '2022-06-29': { edition: '115', date: '2022-06-29', word: 'CLUBE' },
  '2022-06-30': { edition: '116', date: '2022-06-30', word: 'PINHA' },
  '2022-07-01': { edition: '117', date: '2022-07-01', word: 'PEDAL' },
  '2022-07-02': { edition: '118', date: '2022-07-02', word: 'AFROS' },
  '2022-07-03': { edition: '119', date: '2022-07-03', word: 'MELOU' },
  '2022-07-04': { edition: '120', date: '2022-07-04', word: 'CONDE' },
  '2022-07-05': { edition: '121', date: '2022-07-05', word: 'PERUS', meaning: 'ave da fam. dos meleagridídeos ( Meleagris gallopavo ), encontrada em todo o mundo como animal doméstico, apenas na América do Norte em estado selvagem, de plumagem escura, com reflexos verde-metálicos e bronzeados, cabeça nua, dotada de uma grande carúncula e cauda larga e arredondada.' },
  '2022-07-06': { edition: '122', date: '2022-07-06', word: 'TOCAR' },
  '2022-07-07': { edition: '123', date: '2022-07-07', word: 'LUCRO' },
  '2022-07-08': { edition: '124', date: '2022-07-08', word: 'AVIDO', meaning: 'ÁVIDO: que deseja com ardor.' },
  '2022-07-09': { edition: '125', date: '2022-07-09', word: 'QUINA' },
  '2022-07-10': { edition: '126', date: '2022-07-10', word: 'VASTO' },
  '2022-07-11': { edition: '127', date: '2022-07-11', word: 'ENFIA' },
  '2022-07-12': { edition: '128', date: '2022-07-12', word: 'COSTA' },
  '2022-07-13': { edition: '129', date: '2022-07-13', word: 'BOATE' },
  '2022-07-14': { edition: '130', date: '2022-07-14', word: 'ROUPA' },
  '2022-07-15': { edition: '131', date: '2022-07-15', word: 'MENOS' },
  '2022-07-16': { edition: '132', date: '2022-07-16', word: 'FILAS' },
  '2022-07-17': { edition: '133', date: '2022-07-17', word: 'LATIR' },
  '2022-07-18': { edition: '134', date: '2022-07-18', word: 'IGUAL' },
  '2022-07-19': { edition: '135', date: '2022-07-19', word: 'INUME' },
  '2022-07-20': { edition: '136', date: '2022-07-20', word: 'FOCAR' },
  '2022-07-21': { edition: '137', date: '2022-07-21', word: 'HONRA' },
  '2022-07-22': { edition: '138', date: '2022-07-22', word: 'LOGAR' },
  '2022-07-23': { edition: '139', date: '2022-07-23', word: 'ABREM' },
  '2022-07-24': { edition: '140', date: '2022-07-24', word: 'TRICO', meaning: 'TRICÔ: tecido de malhas entrelaçadas, feito à mão, com agulhas especiais ou à máquina.' },
  '2022-07-25': { edition: '141', date: '2022-07-25', word: 'HINDU' },
  '2022-07-26': { edition: '142', date: '2022-07-26', word: 'FUSCA' },
  '2022-07-27': { edition: '143', date: '2022-07-27', word: 'RODAM' },
  '2022-07-28': { edition: '144', date: '2022-07-28', word: 'EXILA', meaning: 'Expulsa, bane, desterra, degrada.' },
  '2022-07-29': { edition: '145', date: '2022-07-29', word: 'DENSA' },
  '2022-07-30': { edition: '146', date: '2022-07-30', word: 'JUSTA' },
  '2022-07-31': { edition: '147', date: '2022-07-31', word: 'MOLAS' },
  '2022-08-01': { edition: '148', date: '2022-08-01', word: 'LINDA' },
  '2022-08-02': { edition: '149', date: '2022-08-02', word: 'BUNDA' },
  '2022-08-03': { edition: '150', date: '2022-08-03', word: 'RUIDO' },
  '2022-08-04': { edition: '151', date: '2022-08-04', word: 'SECOU' },
  '2022-08-05': { edition: '152', date: '2022-08-05', word: 'FUMOS' },
  '2022-08-06': { edition: '153', date: '2022-08-06', word: 'FALSO' },
  '2022-08-07': { edition: '154', date: '2022-08-07', word: 'BATEU' },
  '2022-08-08': { edition: '155', date: '2022-08-08', word: 'LINHA' },
  '2022-08-09': { edition: '156', date: '2022-08-09', word: 'CRAVO' },
  '2022-08-10': { edition: '157', date: '2022-08-10', word: 'URINA' },
  '2022-08-11': { edition: '158', date: '2022-08-11', word: 'GANHO' },
  '2022-08-12': { edition: '159', date: '2022-08-12', word: 'LIMPA' },
  '2022-08-13': { edition: '160', date: '2022-08-13', word: 'VOTEM' },
  '2022-08-14': { edition: '161', date: '2022-08-14', word: 'ISOLA' },
  '2022-08-15': { edition: '162', date: '2022-08-15', word: 'CHIAR' },
  '2022-08-16': { edition: '163', date: '2022-08-16', word: 'ESTAR' },
  '2022-08-17': { edition: '164', date: '2022-08-17', word: 'LAICO' },
  '2022-08-18': { edition: '165', date: '2022-08-18', word: 'OSCAR' },
  '2022-08-19': { edition: '166', date: '2022-08-19', word: 'RECUO' },
  '2022-08-20': { edition: '167', date: '2022-08-20', word: 'MOLDE' },
  '2022-08-21': { edition: '168', date: '2022-08-21', word: 'SALVO' },
  '2022-08-22': { edition: '169', date: '2022-08-22', word: 'FISGA' },
  '2022-08-23': { edition: '170', date: '2022-08-23', word: 'CINZA' },
  '2022-08-24': { edition: '171', date: '2022-08-24', word: 'MONGE' },
  '2022-08-25': { edition: '172', date: '2022-08-25', word: 'URNAS' },
  '2022-08-26': { edition: '173', date: '2022-08-26', word: 'LOUSA' },
  '2022-08-27': { edition: '174', date: '2022-08-27', word: 'PONEI' },
  '2022-08-28': { edition: '175', date: '2022-08-28', word: 'PREGA' },
  '2022-08-29': { edition: '176', date: '2022-08-29', word: 'ROUBA' },
  '2022-08-30': { edition: '177', date: '2022-08-30', word: 'ACETO' },
  '2022-08-31': { edition: '178', date: '2022-08-31', word: 'FRISA' },
  '2022-09-01': { edition: '179', date: '2022-09-01', word: 'CHEIO' },
  '2022-09-02': { edition: '180', date: '2022-09-02', word: 'CHUVA' },
  '2022-09-03': { edition: '181', date: '2022-09-03', word: 'BEATO', meaning: 'que ou quem goza da bem-aventurança celeste; bem-aventurado.' },
  '2022-09-04': { edition: '182', date: '2022-09-04', word: 'ZELAR', meaning: 'ter zelo por; vigiar, proteger, tomar conta de (alguém ou algo) com toda a atenção, cuidado e interesse; velar' },
  '2022-09-05': { edition: '183', date: '2022-09-05', word: 'PICOS' },
  '2022-09-06': { edition: '184', date: '2022-09-06', word: 'LUTAR' },
  '2022-09-07': { edition: '185', date: '2022-09-07', word: 'TROPA' },
  '2022-09-08': { edition: '186', date: '2022-09-08', word: 'FILMA' },
  '2022-09-09': { edition: '187', date: '2022-09-09', word: 'NEVOU' },
  '2022-09-10': { edition: '188', date: '2022-09-10', word: 'TOCHA' },
  '2022-09-11': { edition: '189', date: '2022-09-11', word: 'NOTAS' },
  '2022-09-12': { edition: '190', date: '2022-09-12', word: 'BUSCA' },
  '2022-09-13': { edition: '191', date: '2022-09-13', word: 'ENJOA' },
  '2022-09-14': { edition: '192', date: '2022-09-14', word: 'CESTA' },
  '2022-09-15': { edition: '193', date: '2022-09-15', word: 'CHUPA' },
  '2022-09-16': { edition: '194', date: '2022-09-16', word: 'BROCA' },
  '2022-09-17': { edition: '195', date: '2022-09-17', word: 'BRISA' },
  '2022-09-18': { edition: '196', date: '2022-09-18', word: 'LUCRO' },
  '2022-09-19': { edition: '197', date: '2022-09-19', word: 'PENTA' },
  '2022-09-20': { edition: '198', date: '2022-09-20', word: 'MALES' },
  '2022-09-21': { edition: '199', date: '2022-09-21', word: 'AGUDO' },
  '2022-09-22': { edition: '200', date: '2022-09-22', word: 'FRESA' },
  '2022-09-23': { edition: '201', date: '2022-09-23', word: 'LINFA' },
  '2022-09-24': { edition: '202', date: '2022-09-24', word: 'MALTE' },
  '2022-09-25': { edition: '203', date: '2022-09-25', word: 'RODAS' },
  '2022-09-26': { edition: '204', date: '2022-09-26', word: 'FIRMA' },
  '2022-09-27': { edition: '205', date: '2022-09-27', word: 'CONTA' },
  '2022-09-28': { edition: '206', date: '2022-09-28', word: 'TEIMA' },
  '2022-09-29': { edition: '207', date: '2022-09-29', word: 'MOTEL' },
  '2022-09-30': { edition: '208', date: '2022-09-30', word: 'CURSO' },
  '2022-10-01': { edition: '209', date: '2022-10-01', word: 'SUNGA' },
  '2022-10-02': { edition: '210', date: '2022-10-02', word: 'GOSMA' },
  '2022-10-03': { edition: '211', date: '2022-10-03', word: 'EXITO' },
  '2022-10-04': { edition: '212', date: '2022-10-04', word: 'BIELA' },
  '2022-10-05': { edition: '213', date: '2022-10-05', word: 'FRUTA' },
  '2022-10-06': { edition: '214', date: '2022-10-06', word: 'HIDRA' },
  '2022-10-07': { edition: '215', date: '2022-10-07', word: 'BEIRA' },
  '2022-10-08': { edition: '216', date: '2022-10-08', word: 'LIBRA' },
  '2022-10-09': { edition: '217', date: '2022-10-09', word: 'CHULO' },
  '2022-10-10': { edition: '218', date: '2022-10-10', word: 'BELGA' },
  '2022-10-11': { edition: '219', date: '2022-10-11', word: 'GRUPO' },
  '2022-10-12': { edition: '220', date: '2022-10-12', word: 'FOICE' },
  '2022-10-13': { edition: '221', date: '2022-10-13', word: 'NEVOA' },
  '2022-10-14': { edition: '222', date: '2022-10-14', word: 'MORAL' },
  '2022-10-15': { edition: '223', date: '2022-10-15', word: 'SURTO' },
  '2022-10-16': { edition: '224', date: '2022-10-16', word: 'IDEAL' },
  '2022-10-17': { edition: '225', date: '2022-10-17', word: 'AMPLO' },
  '2022-10-18': { edition: '226', date: '2022-10-18', word: 'BECOS' },
  '2022-10-19': { edition: '227', date: '2022-10-19', word: 'FLUIR' },
  '2022-10-20': { edition: '228', date: '2022-10-20', word: 'FUNIL' },
  '2022-10-21': { edition: '229', date: '2022-10-21', word: 'PULSO' },
  '2022-10-22': { edition: '230', date: '2022-10-22', word: 'FONTE' },
  '2022-10-23': { edition: '231', date: '2022-10-23', word: 'PAVOR' },
  '2022-10-24': { edition: '232', date: '2022-10-24', word: 'QUEDA' },
  '2022-10-25': { edition: '233', date: '2022-10-25', word: 'MUDOS' },
  '2022-10-26': { edition: '234', date: '2022-10-26', word: 'SEXTA' },
  '2022-10-27': { edition: '235', date: '2022-10-27', word: 'JEITO' },
  '2022-10-28': { edition: '236', date: '2022-10-28', word: 'JUNTA' },
  '2022-10-29': { edition: '237', date: '2022-10-29', word: 'BANCO' },
  '2022-10-30': { edition: '238', date: '2022-10-30', word: 'VOTAR' },
  '2022-10-31': { edition: '239', date: '2022-10-31', word: 'BESTA' },
  '2022-11-01': { edition: '240', date: '2022-11-01', word: 'FOLHA' },
  '2022-11-02': { edition: '241', date: '2022-11-02', word: 'VULGO' },
  '2022-11-03': { edition: '242', date: '2022-11-03', word: 'PESCA' },
  '2022-11-04': { edition: '243', date: '2022-11-04', word: 'LETRA' },
  '2022-11-05': { edition: '244', date: '2022-11-05', word: 'MARES' },
  '2022-11-06': { edition: '245', date: '2022-11-06', word: 'MORNA' },
  '2022-11-07': { edition: '246', date: '2022-11-07', word: 'SOLTA' },
  '2022-11-08': { edition: '247', date: '2022-11-08', word: 'GUIZO' },
  '2022-11-09': { edition: '248', date: '2022-11-09', word: 'ATIVO' },
  '2022-11-10': { edition: '249', date: '2022-11-10', word: 'LATEX' },
  '2022-11-11': { edition: '250', date: '2022-11-11', word: 'BISCO' },
  '2022-11-12': { edition: '251', date: '2022-11-12', word: 'BRIGA' },
  '2022-11-13': { edition: '252', date: '2022-11-13', word: 'MERDA' },
  '2022-11-14': { edition: '253', date: '2022-11-14', word: 'BISPO' },
  '2022-11-15': { edition: '254', date: '2022-11-15', word: 'PLUGA' },
  '2022-11-16': { edition: '255', date: '2022-11-16', word: 'TAMPO' },
  '2022-11-17': { edition: '256', date: '2022-11-17', word: 'CANTO' },
  '2022-11-18': { edition: '257', date: '2022-11-18', word: 'BINGO' },
  '2022-11-19': { edition: '258', date: '2022-11-19', word: 'SONHE' },
  '2022-11-20': { edition: '259', date: '2022-11-20', word: 'BROTA' },
  '2022-11-21': { edition: '260', date: '2022-11-21', word: 'METRO' },
  '2022-11-22': { edition: '261', date: '2022-11-22', word: 'APITO' },
  '2022-11-23': { edition: '262', date: '2022-11-23', word: 'MICHA', meaning: 'pão feito da mistura de diversas farinhas.' },
  '2022-11-24': { edition: '263', date: '2022-11-24', word: 'FUGAR', meaning: 'afugentar (alguém ou algo); pôr em fuga.' },
  '2022-11-25': { edition: '264', date: '2022-11-25', word: 'PERTO' },
  '2022-11-26': { edition: '265', date: '2022-11-26', word: 'EXATO' },
  '2022-11-27': { edition: '266', date: '2022-11-27', word: 'OLIVA' },
  '2022-11-28': { edition: '267', date: '2022-11-28', word: 'GRITA' },
  '2022-11-29': { edition: '268', date: '2022-11-29', word: 'SOBRA' },
  '2022-11-30': { edition: '269', date: '2022-11-30', word: 'BASCO', meaning: 'indivíduo natural ou habitante do País Basco [Região dos Pireneus, incluindo parte da França e parte da Espanha.].' },
  '2022-12-01': { edition: '270', date: '2022-12-01', word: 'FALSO' },
  '2022-12-02': { edition: '271', date: '2022-12-02', word: 'FILME' },
  '2022-12-03': { edition: '272', date: '2022-12-03', word: 'RANGE' },
  '2022-12-04': { edition: '273', date: '2022-12-04', word: 'BANHO' },
  '2022-12-05': { edition: '274', date: '2022-12-05', word: 'FUSTE', meaning: 'pau, acha de lenha. "acha: pequeno pedaço de madeira us. para lenha."' },
  '2022-12-06': { edition: '275', date: '2022-12-06', word: 'BLUSA' },
  '2022-12-07': { edition: '276', date: '2022-12-07', word: 'CIFRA' },
  '2022-12-08': { edition: '277', date: '2022-12-08', word: 'SENHA' },
  '2022-12-09': { edition: '278', date: '2022-12-09', word: 'FUNGO' },
  '2022-12-10': { edition: '279', date: '2022-12-10', word: 'ASILO' },
  '2022-12-11': { edition: '280', date: '2022-12-11', word: 'FERMO' },
  '2022-12-12': { edition: '281', date: '2022-12-12', word: 'AMIGO' },
  '2022-12-13': { edition: '282', date: '2022-12-13', word: 'LOUSA' },
  '2022-12-14': { edition: '283', date: '2022-12-14', word: 'MILHA' },
  '2022-12-15': { edition: '284', date: '2022-12-15', word: 'TULES' },
  '2022-12-16': { edition: '285', date: '2022-12-16', word: 'TECLA' },
  '2022-12-17': { edition: '286', date: '2022-12-17', word: 'SIRTE', meaning: 'denominação genérica de bancos de areia, ilhotas, recifes etc., acidentes de grande perigo para a navegação marítima.' },
  '2022-12-18': { edition: '287', date: '2022-12-18', word: 'SURTO' },
  '2022-12-19': { edition: '288', date: '2022-12-19', word: 'LAMBE' },
  '2022-12-20': { edition: '289', date: '2022-12-20', word: 'SELAR' },
  '2022-12-21': { edition: '290', date: '2022-12-21', word: 'TRIPA' },
  '2022-12-22': { edition: '291', date: '2022-12-22', word: 'GOZAR' },
  '2022-12-23': { edition: '292', date: '2022-12-23', word: 'FARNO', meaning: 'sensação de mal-estar, de inquietação.' },
  '2022-12-24': { edition: '293', date: '2022-12-24', word: 'CEGAR' },
  '2022-12-25': { edition: '294', date: '2022-12-25', word: 'PENCA' },
  '2022-12-26': { edition: '295', date: '2022-12-26', word: 'GUIAR' },
  '2022-12-27': { edition: '296', date: '2022-12-27', word: 'QUIBE' },
  '2022-12-28': { edition: '297', date: '2022-12-28', word: 'DUZIA' },
  '2022-12-29': { edition: '298', date: '2022-12-29', word: 'NOITE' },
  '2022-12-30': { edition: '299', date: '2022-12-30', word: 'MUNDO' },
  '2022-12-31': { edition: '300', date: '2022-12-31', word: 'XENIA', meaning: 'na antiga Grécia, qualidade de estrangeiro; situação política e social de meteco; estraneidade. "qualidade de estrangeiro; acolhida hospitaleira"' },
  '2023-01-01': { edition: '301', date: '2023-01-01', word: 'CURVO' },
  '2023-01-02': { edition: '302', date: '2023-01-02', word: 'MILHA' },
  '2023-01-03': { edition: '303', date: '2023-01-03', word: 'RICAS' },
  '2023-01-04': { edition: '304', date: '2023-01-04', word: 'MIADO' },
  '2023-01-05': { edition: '305', date: '2023-01-05', word: 'CHOPE' },
  '2023-01-06': { edition: '306', date: '2023-01-06', word: 'FOLHA' },
  '2023-01-07': { edition: '307', date: '2023-01-07', word: 'GASTO' },
  '2023-01-08': { edition: '308', date: '2023-01-08', word: 'CRIVO', meaning: 'utensílio com o fundo perfurado e que se usa para separar fragmentos, grãos, pedras preciosas e congêneres, de acordo com o volume e a espessura; joeira, peneira.' },
  '2023-01-09': { edition: '309', date: '2023-01-09', word: 'DISCO' },
  '2023-01-10': { edition: '310', date: '2023-01-10', word: 'TRUCO' },
  '2023-01-11': { edition: '311', date: '2023-01-11', word: 'PUNIR' },
  '2023-01-12': { edition: '312', date: '2023-01-12', word: 'CUIDO' },
  '2023-01-13': { edition: '313', date: '2023-01-13', word: 'TROIA' },
  '2023-01-14': { edition: '314', date: '2023-01-14', word: 'NEURA' },
  '2023-01-15': { edition: '315', date: '2023-01-15', word: 'BURGO', meaning: 'na Idade Média, fortaleza ou sítio fortificado, ocupado por uma guarnição militar e pelos civis necessários a sua manutenção, que, em caso de ataque inimigo, servia de abrigo às populações que viviam fora de suas muralhas.' },
  '2023-01-16': { edition: '316', date: '2023-01-16', word: 'FERAZ', meaning: 'muito produtivo ou abundante; fecundo, fértil.' },
  '2023-01-17': { edition: '317', date: '2023-01-17', word: 'JUNTO' },
  '2023-01-18': { edition: '318', date: '2023-01-18', word: 'ETINO', meaning: 'relativo a ou habitante de uma cidade da Sicília (Itália) de nome e localização não registrados.' },
  '2023-01-19': { edition: '319', date: '2023-01-19', word: 'DOBRA' },
  '2023-01-20': { edition: '320', date: '2023-01-20', word: 'MEIGA' },
  '2023-01-21': { edition: '321', date: '2023-01-21', word: 'BOLSA' },
  '2023-01-22': { edition: '322', date: '2023-01-22', word: 'PERNO', meaning: 'eixo cilíndrico de diversas máquinas.' },
  '2023-01-23': { edition: '323', date: '2023-01-23', word: 'MOLDE' },
  '2023-01-24': { edition: '324', date: '2023-01-24', word: 'PEDRA' },
  '2023-01-25': { edition: '325', date: '2023-01-25', word: 'CAMBO', meaning: 'vara recurvada numa das extremidades, ou com um gancho, us. para colher frutas em árvores altas; cambão, ladra.' },
  '2023-01-26': { edition: '326', date: '2023-01-26', word: 'AFETO' },
  '2023-01-27': { edition: '327', date: '2023-01-27', word: 'TRIPE', meaning: 'TRIPÉ: suporte portátil com três escoras, sobre o qual se assenta máquina fotográfica, telescópio, filmadora ou outro aparelho.' },
  '2023-01-28': { edition: '328', date: '2023-01-28', word: 'LESAO', meaning: 'LESÃO: ferimento ou traumatismo.' },
  '2023-01-29': { edition: '329', date: '2023-01-29', word: 'CURTA' },
  '2023-01-30': { edition: '330', date: '2023-01-30', word: 'ULTRA' },
  '2023-01-31': { edition: '331', date: '2023-01-31', word: 'UNICO', meaning: 'ÚNICO' },
  '2023-02-01': { edition: '332', date: '2023-02-01', word: 'TURVA' },
  '2023-02-02': { edition: '333', date: '2023-02-02', word: 'ALBUM' },
  '2023-02-03': { edition: '334', date: '2023-02-03', word: 'BISPA' },
  '2023-02-04': { edition: '335', date: '2023-02-04', word: 'CISEL' },
  '2023-02-05': { edition: '336', date: '2023-02-05', word: 'PERDA' },
  '2023-02-06': { edition: '337', date: '2023-02-06', word: 'CORTE' },
  '2023-02-07': { edition: '338', date: '2023-02-07', word: 'RISCA' },
  '2023-02-08': { edition: '339', date: '2023-02-08', word: 'DIGNO' },
  '2023-02-09': { edition: '340', date: '2023-02-09', word: 'PINTA' },
  '2023-02-10': { edition: '341', date: '2023-02-10', word: 'UMIDO', meaning: 'ÚMIDO' },
  '2023-02-11': { edition: '342', date: '2023-02-11', word: 'BONDE' },
  '2023-02-12': { edition: '343', date: '2023-02-12', word: 'SAUDE', meaning: 'SAÚDE' },
  '2023-02-13': { edition: '344', date: '2023-02-13', word: 'BREGA' },
  '2023-02-14': { edition: '345', date: '2023-02-14', word: 'CEROL' },
  '2023-02-15': { edition: '346', date: '2023-02-15', word: 'COUVE' },
  '2023-02-16': { edition: '347', date: '2023-02-16', word: 'PEQUI' },
  '2023-02-17': { edition: '348', date: '2023-02-17', word: 'UNGIR' },
  '2023-02-18': { edition: '349', date: '2023-02-18', word: 'FACHO', meaning: 'material inflamável que se acende para iluminação ou como sinal; archote.' },
  '2023-02-19': { edition: '350', date: '2023-02-19', word: 'VIDRO' },
  '2023-02-20': { edition: '351', date: '2023-02-20', word: 'AUDIO', meaning: 'ÁUDIO' },
  '2023-02-21': { edition: '352', date: '2023-02-21', word: 'TURFA', meaning: 'massa de tecido de várias plantas, esp. de musgos do gên. Sphagnum, produzida por lenta decomposição anaeróbica associada à ação da água, encontrada em várias partes do mundo, com variação de consistência (do torrão até o limo) e de cor (de verde-claro ao negro), us. como fertilizante, forragem, combustível e na feitura de carvão.' },
  '2023-02-22': { edition: '353', date: '2023-02-22', word: 'COBRA' },
  '2023-02-23': { edition: '354', date: '2023-02-23', word: 'SAQUE' },
  '2023-02-24': { edition: '355', date: '2023-02-24', word: 'FURNA', meaning: 'cavidade profunda na encosta de uma rocha, floresta etc.; caverna, gruta, cova. "o homem primitivo transformava f. em habitações grupais"' },
  '2023-02-25': { edition: '356', date: '2023-02-25', word: 'FROTA' },
  '2023-02-26': { edition: '357', date: '2023-02-26', word: 'SURTA' },
  '2023-02-27': { edition: '358', date: '2023-02-27', word: 'CLARO' },
  '2023-02-28': { edition: '359', date: '2023-02-28', word: 'JEANS' },
  '2023-03-01': { edition: '360', date: '2023-03-01', word: 'CAPIM' },
  '2023-03-02': { edition: '361', date: '2023-03-02', word: 'MOLAR' },
  '2023-03-03': { edition: '362', date: '2023-03-03', word: 'FURTA' },
  '2023-03-04': { edition: '363', date: '2023-03-04', word: 'EDUCA' },
  '2023-03-05': { edition: '364', date: '2023-03-05', word: 'JOULE', meaning: 'unidade de energia ou trabalho no Sistema Internacional (SI), definida como o trabalho realizado por uma força com magnitude de um newton quando o ponto em que a força é aplicada se desloca um metro na direção da força [símb.: J ].' },
  '2023-03-06': { edition: '365', date: '2023-03-06', word: 'PREIA', meaning: 'm.q. PRESA ("animal caçado").' },
  '2023-03-07': { edition: '368', date: '2023-03-07', word: 'VEMOS' },
  '2023-03-08': { edition: '367', date: '2023-03-08', word: 'TROCA' },
  '2023-03-09': { edition: '369', date: '2023-03-09', word: 'DEITA' },
  '2023-03-10': { edition: '370', date: '2023-03-10', word: 'BALDE' },
  '2023-03-11': { edition: '371', date: '2023-03-11', word: 'FUNDO' },
  '2023-03-12': { edition: '372', date: '2023-03-12', word: 'FALIR' },
  '2023-03-13': { edition: '373', date: '2023-03-13', word: 'RASTO', meaning: 'pegada, vestígio deixado por animal ou pessoa no seu caminho.' },
  '2023-03-14': { edition: '374', date: '2023-03-14', word: 'BELAS' },
  '2023-03-15': { edition: '375', date: '2023-03-15', word: 'PISTA' },
  '2023-03-16': { edition: '376', date: '2023-03-16', word: 'GRADO', meaning: 'vontade, desejo.' },
  '2023-03-17': { edition: '377', date: '2023-03-17', word: 'SOFRE' },
  '2023-03-18': { edition: '378', date: '2023-03-18', word: 'COTAS' },
  '2023-03-19': { edition: '379', date: '2023-03-19', word: 'MANTO' },
  '2023-03-20': { edition: '380', date: '2023-03-20', word: 'MENIR', meaning: 'monumento megalítico do período neolítico, ger. de forma alongada, altura variável (até cerca de 11 m) e fixado verticalmente no solo [Podia servir de marco astronômico ou representar o totem ou outros espíritos.].' },
  '2023-03-21': { edition: '381', date: '2023-03-21', word: 'TOUCA' },
  '2023-03-22': { edition: '382', date: '2023-03-22', word: 'FICHA' },
  '2023-03-23': { edition: '383', date: '2023-03-23', word: 'REINA' },
  '2023-03-24': { edition: '384', date: '2023-03-24', word: 'PLATO', meaning: 'PLATÔ: planalto; disco que integra o dispositivo responsável pela transmissão da força do motor às rodas de tração.' },
  '2023-03-25': { edition: '385', date: '2023-03-25', word: 'PURGA', meaning: 'm.q. LAXANTE (subst.).' },
  '2023-03-26': { edition: '386', date: '2023-03-26', word: 'LOIRA' },
  '2023-03-27': { edition: '387', date: '2023-03-27', word: 'DUPLA' },
  '2023-03-28': { edition: '388', date: '2023-03-28', word: 'USADO' },
  '2023-03-29': { edition: '389', date: '2023-03-29', word: 'VILAS' },
  '2023-03-30': { edition: '390', date: '2023-03-30', word: 'JAMBO', meaning: 'árvore ( Syzygium jambos ) da fam. das mirtáceas, com casca adstringente, folhas elípticas e flores melíferas, em corimbos ou racemos, nativa da Ásia e muito cultivada em várias regiões tropicais, como ornamental, como quebra-vento e esp. pelos frutos comestíveis; eugênia, jambeiro, jambo-rosa.' },
  '2023-03-31': { edition: '391', date: '2023-03-31', word: 'ANTRO', meaning: 'gruta natural, profunda e escura, ger. servindo de abrigo a feras; furna.' },
  '2023-04-01': { edition: '392', date: '2023-04-01', word: 'TORCE' },
  '2023-04-02': { edition: '393', date: '2023-04-02', word: 'LUSCO', meaning: 'que vê de um só olho; estrábico, vesgo.' },
  '2023-04-03': { edition: '394', date: '2023-04-03', word: 'TELAO', meaning: 'TELÃO' },
  '2023-04-04': { edition: '395', date: '2023-04-04', word: 'ROCHA' },
  '2023-04-05': { edition: '396', date: '2023-04-05', word: 'PERNA' },
  '2023-04-06': { edition: '397', date: '2023-04-06', word: 'NEURO', meaning: 'relativo aos neuros, povo da Cítia europeia, ou o seu habitante.; INFORMAL•BRASILEIRISMO "neurótico."' },
  '2023-04-07': { edition: '398', date: '2023-04-07', word: 'AMIDO' },
  '2023-04-08': { edition: '399', date: '2023-04-08', word: 'GOLFE' },
  '2023-04-09': { edition: '400', date: '2023-04-09', word: 'PULOS' },
  '2023-04-10': { edition: '401', date: '2023-04-10', word: 'TUMBA' },
  '2023-04-11': { edition: '402', date: '2023-04-11', word: 'PECAR' },
  '2023-04-12': { edition: '403', date: '2023-04-12', word: 'OSTRA' },
  '2023-04-13': { edition: '404', date: '2023-04-13', word: 'MEDIR' },
  '2023-04-14': { edition: '405', date: '2023-04-14', word: 'LARES' },
  '2023-04-15': { edition: '406', date: '2023-04-15', word: 'RENAL' },
  '2023-04-16': { edition: '407', date: '2023-04-16', word: 'BRITA' },
  '2023-04-17': { edition: '408', date: '2023-04-17', word: 'BACON' },
  '2023-04-18': { edition: '409', date: '2023-04-18', word: 'BUVAR', meaning: 'peça de madeira, metal ou qualquer outro material, à qual se prende o mata-borrão; berço.' },
  '2023-04-19': { edition: '410', date: '2023-04-19', word: 'DIETA' },
  '2023-04-20': { edition: '411', date: '2023-04-20', word: 'SIGLA' },
  '2023-04-21': { edition: '412', date: '2023-04-21', word: 'OGIVA' },
  '2023-04-22': { edition: '413', date: '2023-04-22', word: 'CLERO' },
  '2023-04-23': { edition: '414', date: '2023-04-23', word: 'VINHO' },
  '2023-04-24': { edition: '415', date: '2023-04-24', word: 'JUSTO' },
  '2023-04-25': { edition: '416', date: '2023-04-25', word: 'FORMA' },
  '2023-04-26': { edition: '417', date: '2023-04-26', word: 'GLOTE', meaning: 'abertura triangular na parte mais estreita da laringe, circunscrita pelas duas pregas vocais inferiores, com cerca de 16 mm de comprimento e abertura máxima de cerca de 12 mm.' },
  '2023-04-27': { edition: '418', date: '2023-04-27', word: 'COLAR' },
  '2023-04-28': { edition: '419', date: '2023-04-28', word: 'LENTA' },
  '2023-04-29': { edition: '420', date: '2023-04-29', word: 'RIFLE' },
  '2023-04-30': { edition: '421', date: '2023-04-30', word: 'NEDIO', meaning: 'NÉDIO: que reluz; brilhante, luzidio.' },
  '2023-05-01': { edition: '422', date: '2023-05-01', word: 'GEMAS' },
  '2023-05-02': { edition: '423', date: '2023-05-02', word: 'TENAR', meaning: 'TÊNAR: saliência carnosa que, na palma da mão, corresponde à base do polegar.' },
  '2023-05-03': { edition: '424', date: '2023-05-03', word: 'CIUME', meaning: 'CIÚME' },
  '2023-05-04': { edition: '425', date: '2023-05-04', word: 'FRASE' },
  '2023-05-05': { edition: '426', date: '2023-05-05', word: 'SONHA' },
  '2023-05-06': { edition: '427', date: '2023-05-06', word: 'CULTA' },
  '2023-05-07': { edition: '428', date: '2023-05-07', word: 'FINAL' },
  '2023-05-08': { edition: '429', date: '2023-05-08', word: 'OLHAM' },
  '2023-05-09': { edition: '430', date: '2023-05-09', word: 'JULHO' },
  '2023-05-10': { edition: '431', date: '2023-05-10', word: 'ALFOZ', meaning: 'território dependente de uma povoação ou de um castelo; arredores, cercanias.' },
  '2023-05-11': { edition: '432', date: '2023-05-11', word: 'FULVO', meaning: 'cor amarelada, alaranjada ou amarelo-ouro; castanho-avermelhado, louro, ocre.' },
  '2023-05-12': { edition: '433', date: '2023-05-12', word: 'JUIZA', meaning: 'JUÍZA' },
  '2023-05-13': { edition: '434', date: '2023-05-13', word: 'CORTA' },
  '2023-05-14': { edition: '435', date: '2023-05-14', word: 'MURTA', meaning: 'Designação comum às plantas (árvores e arbustos) da família das mirtáceas, sendo maioritariamente cultivadas para ornamentação, extração de frutos, extração de madeira, óleos terapêuticos; o fruto que provém dessas árvores.' },
  '2023-05-15': { edition: '436', date: '2023-05-15', word: 'SKATE' },
  '2023-05-16': { edition: '437', date: '2023-05-16', word: 'LUTAS' },
  '2023-05-17': { edition: '438', date: '2023-05-17', word: 'SIRVA' },
  '2023-05-18': { edition: '439', date: '2023-05-18', word: 'DECAI', meaning: 'Decai vem do verbo decair. O mesmo que: declina, caduca, desfalece, arruína, afunde.' },
  '2023-05-19': { edition: '440', date: '2023-05-19', word: 'PICHE' },
  '2023-05-20': { edition: '441', date: '2023-05-20', word: 'GALHO' },
  '2023-05-21': { edition: '442', date: '2023-05-21', word: 'LORDE' },
  '2023-05-22': { edition: '443', date: '2023-05-22', word: 'XUCRO' },
  '2023-05-23': { edition: '444', date: '2023-05-23', word: 'FITAS' },
  '2023-05-24': { edition: '445', date: '2023-05-24', word: 'VIDEO' },
  '2023-05-25': { edition: '446', date: '2023-05-25', word: 'CINTO' },
  '2023-05-26': { edition: '447', date: '2023-05-26', word: 'BOSTA' },
  '2023-05-27': { edition: '448', date: '2023-05-27', word: 'VISOR' },
  '2023-05-28': { edition: '449', date: '2023-05-28', word: 'LEIGO' },
  '2023-05-29': { edition: '450', date: '2023-05-29', word: 'POLAR' },
  '2023-05-30': { edition: '451', date: '2023-05-30', word: 'TENAZ', meaning: 'que apresenta resistência, coesão (diz-se de substância)' },
  '2023-05-31': { edition: '452', date: '2023-05-31', word: 'FINOS' },
  '2023-06-01': { edition: '453', date: '2023-06-01', word: 'CINTA' },
  '2023-06-02': { edition: '454', date: '2023-06-02', word: 'LIGAR' },
  '2023-06-03': { edition: '455', date: '2023-06-03', word: 'TEIXO', meaning: 'design. comum às árvores e arbustos, perenes e dioicos, do gên. Taxus, da fam. das taxáceas, com folhas aciculares, amarelas na parte inferior, e venenosas, como as sementes, que são envoltas por arilo carnoso, suculento e vermelho, muito apreciado por pássaros e outros animais [Nativos de regiões temperadas do hemisfério norte à Malásia e ao México, são ger. cultivados para cercas vivas, pelas madeiras e como ornamentais, com muitos híbridos e variedades.]' },
  '2023-06-04': { edition: '456', date: '2023-06-04', word: 'SUPOR' },
  '2023-06-05': { edition: '457', date: '2023-06-05', word: 'TURMA' },
  '2023-06-06': { edition: '458', date: '2023-06-06', word: 'VELAS' },
  '2023-06-07': { edition: '459', date: '2023-06-07', word: 'SUTIL' },
  '2023-06-08': { edition: '460', date: '2023-06-08', word: 'TURNE', meaning: 'TURNÊ: viagem, com itinerário e paradas predeterminadas, de uma pessoa ou grupo de pessoas, esp. de uma companhia teatral, um conjunto musical, um artista (mimo, músico etc.) ao exterior ou pelo interior de um país; excursão artística, tour' },
  '2023-06-09': { edition: '461', date: '2023-06-09', word: 'ROLHA' },
  '2023-06-10': { edition: '462', date: '2023-06-10', word: 'UNIDO' },
  '2023-06-11': { edition: '463', date: '2023-06-11', word: 'BICHA' },
  '2023-06-12': { edition: '464', date: '2023-06-12', word: 'GOLPE' },
  '2023-06-13': { edition: '465', date: '2023-06-13', word: 'CLIMA' },
  '2023-06-14': { edition: '466', date: '2023-06-14', word: 'IMPAR', meaning: 'respirar mal; abafar, ofegar.' },
  '2023-06-15': { edition: '467', date: '2023-06-15', word: 'BODAS' },
  '2023-06-16': { edition: '468', date: '2023-06-16', word: 'TONER' },
  '2023-06-17': { edition: '469', date: '2023-06-17', word: 'CERTA' },
  '2023-06-18': { edition: '470', date: '2023-06-18', word: 'PANDO', meaning: 'que sofreu aumento de volume; inchado, inflado' },
  '2023-06-19': { edition: '471', date: '2023-06-19', word: 'GLACE' },
  '2023-06-20': { edition: '472', date: '2023-06-20', word: 'VAGOU' },
  '2023-06-21': { edition: '473', date: '2023-06-21', word: 'FLAVO', meaning: 'amarelado ou dourado, como o trigo maduro; fulvo, louro' },
  '2023-06-22': { edition: '474', date: '2023-06-22', word: 'TOCAS' },
  '2023-06-23': { edition: '475', date: '2023-06-23', word: 'REAIS' },
  '2023-06-24': { edition: '476', date: '2023-06-24', word: 'LABIO', meaning: 'LÁBIO: no homem e nos vertebrados em geral, cada uma das duas partes carnudas e móveis que constituem externamente o contorno da boca' },
  '2023-06-25': { edition: '477', date: '2023-06-25', word: 'QUILO' },
  '2023-06-26': { edition: '478', date: '2023-06-26', word: 'RALEI' },
  '2023-06-27': { edition: '479', date: '2023-06-27', word: 'ADOTE' },
  '2023-06-28': { edition: '480', date: '2023-06-28', word: 'SEIVA' },
  '2023-06-29': { edition: '481', date: '2023-06-29', word: 'VOLEI', meaning: 'VÔLEI' },
  '2023-06-30': { edition: '482', date: '2023-06-30', word: 'GOMAS' },
  '2023-07-01': { edition: '483', date: '2023-07-01', word: 'RELHA', meaning: 'em arado ou similar, peça que, posicionada à frente das aivecas, perfura e levanta o solo' },
  '2023-07-02': { edition: '484', date: '2023-07-02', word: 'BRUCO', meaning: 'design. comum aos besouros do gên. Bruchus, típico da fam. dos bruquídeos, que inclui alguns carunchos da ervilha e do feijão' },
  '2023-07-03': { edition: '485', date: '2023-07-03', word: 'BICOS' },
  '2023-07-04': { edition: '486', date: '2023-07-04', word: 'CESTO' },
  '2023-07-05': { edition: '487', date: '2023-07-05', word: 'NUBIL', meaning: 'passível de contrair casamento' },
  '2023-07-06': { edition: '488', date: '2023-07-06', word: 'RATOS' },
  '2023-07-07': { edition: '489', date: '2023-07-07', word: 'MENTA' },
  '2023-07-08': { edition: '490', date: '2023-07-08', word: 'TOPAR' },
  '2023-07-09': { edition: '491', date: '2023-07-09', word: 'GAZES' },
  '2023-07-10': { edition: '492', date: '2023-07-10', word: 'ANGUS' },
  '2023-07-11': { edition: '493', date: '2023-07-11', word: 'CEDRO' },
  '2023-07-12': { edition: '494', date: '2023-07-12', word: 'POLIR' },
  '2023-07-13': { edition: '495', date: '2023-07-13', word: 'LUXAR', meaning: 'Ostentar luxo; pompear' },
  '2023-07-14': { edition: '496', date: '2023-07-14', word: 'VEDAM' },
  '2023-07-15': { edition: '497', date: '2023-07-15', word: 'BETAR', meaning: 'fazer betas (\'listras\') em; listrar de outra(s) cor(es)' },
  '2023-07-16': { edition: '498', date: '2023-07-16', word: 'PRESA' },
  '2023-07-17': { edition: '499', date: '2023-07-17', word: 'MINTA' },
  '2023-07-18': { edition: '500', date: '2023-07-18', word: 'MORDE' },
  '2023-07-19': { edition: '501', date: '2023-07-19', word: 'DUETO' },
  '2023-07-20': { edition: '502', date: '2023-07-20', word: 'DIABO' },
  '2023-07-21': { edition: '503', date: '2023-07-21', word: 'LEGUA', meaning: 'Légua: medida de distância em vigor antes da adoção do sistema métrico, cujo valor varia de acordo com a época, país ou região; no Brasil, vale aproximadamente 6.600 m.' },
  '2023-07-22': { edition: '504', date: '2023-07-22', word: 'CUPOM' },
  '2023-07-23': { edition: '505', date: '2023-07-23', word: 'VISAR' },
  '2023-07-24': { edition: '506', date: '2023-07-24', word: 'CUSTA' },
  '2023-07-25': { edition: '507', date: '2023-07-25', word: 'OPERA' },
  '2023-07-26': { edition: '508', date: '2023-07-26', word: 'OMEGA', meaning: 'Ômega: nome da vigésima quarta e última letra do alfabeto grego ω, Ω.' },
  '2023-07-27': { edition: '509', date: '2023-07-27', word: 'DOLAR' },
  '2023-07-28': { edition: '510', date: '2023-07-28', word: 'MUGIR' },
  '2023-07-29': { edition: '511', date: '2023-07-29', word: 'TRUFA' },
  '2023-07-30': { edition: '512', date: '2023-07-30', word: 'PILAO' },
  '2023-07-31': { edition: '513', date: '2023-07-31', word: 'LENDA' },
  '2023-08-01': { edition: '514', date: '2023-08-01', word: 'FISCO', meaning: 'conjunto de órgãos públicos responsável pela determinação e arrecadação de impostos, taxas etc.; fazenda.' },
  '2023-08-02': { edition: '515', date: '2023-08-02', word: 'BLEFA' },
  '2023-08-03': { edition: '516', date: '2023-08-03', word: 'SABEM' },
  '2023-08-04': { edition: '517', date: '2023-08-04', word: 'UNHAS' },
  '2023-08-05': { edition: '518', date: '2023-08-05', word: 'CHORA' },
  '2023-08-06': { edition: '519', date: '2023-08-06', word: 'VIBRA' },
  '2023-08-07': { edition: '520', date: '2023-08-07', word: 'FUROS' },
  '2023-08-08': { edition: '521', date: '2023-08-08', word: 'BUCHO', meaning: 'estômago dos peixes e dos mamíferos.' },
  '2023-08-09': { edition: '522', date: '2023-08-09', word: 'GRAVE' },
  '2023-08-10': { edition: '523', date: '2023-08-10', word: 'CIDRA', meaning: 'fruto da cidreira ( Citrus medica ); laranja-toranja.' },
  '2023-08-11': { edition: '524', date: '2023-08-11', word: 'ECOAR' },
  '2023-08-12': { edition: '525', date: '2023-08-12', word: 'FREIO' },
  '2023-08-13': { edition: '526', date: '2023-08-13', word: 'LARGO' },
  '2023-08-14': { edition: '527', date: '2023-08-14', word: 'TURBO' },
  '2023-08-15': { edition: '528', date: '2023-08-15', word: 'BULAS' },
  '2023-08-16': { edition: '529', date: '2023-08-16', word: 'PIFAR' },
  '2023-08-17': { edition: '530', date: '2023-08-17', word: 'ALUNO' },
  '2023-08-18': { edition: '531', date: '2023-08-18', word: 'BEIJO' },
  '2023-08-19': { edition: '532', date: '2023-08-19', word: 'RIMAS' },
  '2023-08-20': { edition: '533', date: '2023-08-20', word: 'PULAR' },
  '2023-08-21': { edition: '534', date: '2023-08-21', word: 'PARGO', meaning: 'peixe teleósteo perciforme da fam. dos esparídeos ( Pagrus pagrus ), de águas tropicais e temperadas do Atlântico e Mediterrâneo, abundante no Espírito Santo, com até 70 cm de comprimento, dorso róseo a avermelhado com manchas azuladas, ventre mais claro e nadadeiras dorsal, peitoral e caudal rosadas; calunga, pagro, pargo-liso, pargo-olho-de-vidro, pargo-rosa [Espécie de valor comercial, muito comum nos mercados do Rio de Janeiro, Bahia e Espírito Santo.]' },
  '2023-08-22': { edition: '535', date: '2023-08-22', word: 'NOMES' },
  '2023-08-23': { edition: '536', date: '2023-08-23', word: 'PISCO', meaning: '1. que pisca os olhos; piscante, pisqueiro. 2. aguardente peruana feita de uva.' },
  '2023-08-24': { edition: '537', date: '2023-08-24', word: 'TUFAR', meaning: 'aumentar de volume, fazer inchar ou inchar-se; estufar(-se), inflar(-se).' },
  '2023-08-25': { edition: '538', date: '2023-08-25', word: 'GUAPO', meaning: '1. que denota ousadia, coragem; ousado, valente. 2. dotado de elegância e beleza física; bonito, airoso, elegante.' },
  '2023-08-26': { edition: '539', date: '2023-08-26', word: 'TRENA' },
  '2023-08-27': { edition: '540', date: '2023-08-27', word: 'PENAR' },
  '2023-08-28': { edition: '541', date: '2023-08-28', word: 'HUMOR' },
  '2023-08-29': { edition: '542', date: '2023-08-29', word: 'VOZES' },
  '2023-08-30': { edition: '543', date: '2023-08-30', word: 'OPINA' },
  '2023-08-31': { edition: '544', date: '2023-08-31', word: 'ZOINA', meaning: 'diz-se de mulher que se prostitui; meretriz, prostituta.' },
  '2023-09-01': { edition: '545', date: '2023-09-01', word: 'FILHO'},
  '2023-09-02': { edition: '546', date: '2023-09-02', word: 'AZEDO'},
  '2023-09-03': { edition: '547', date: '2023-09-03', word: 'MANSO'},
  '2023-09-04': { edition: '548', date: '2023-09-04', word: 'IMPOR'},
  '2023-09-05': { edition: '549', date: '2023-09-05', word: 'DICAR', meaning: 'destinar com reverência; dedicar, tributar.' },
  '2023-09-06': { edition: '550', date: '2023-09-06', word: 'CURVA'},
  '2023-09-07': { edition: '551', date: '2023-09-07', word: 'PALCO'},
  '2023-09-08': { edition: '552', date: '2023-09-08', word: 'PIUCA', meaning: 'PIÚCA: pedaço de madeira seca que se esfarela, tornando-se de fácil combustão.' },
  '2023-09-09': { edition: '553', date: '2023-09-09', word: 'VERNO', meaning: 'relativo ou pertencente à primavera; vernal.' },
  '2023-09-10': { edition: '554', date: '2023-09-10', word: 'TALIO', meaning: 'elemento químico de número atômico 81 (símb.: Tl ) [Us. em vidros de baixo ponto de fusão, células fotelétricas, pesticidas etc.] ☞ cf. TABELA PERIÓDICA.' },
  '2023-09-11': { edition: '555', date: '2023-09-11', word: 'FLAPE', meaning: 'parte da asa de um avião que pode ser deslocada por rotação em torno de um eixo paralelo à envergadura, a fim de alterar a forma geral e as características aerodinâmicas.' },
  '2023-09-12': { edition: '556', date: '2023-09-12', word: 'LIXAS'},
  '2023-09-13': { edition: '557', date: '2023-09-13', word: 'EXPOR'},
  '2023-09-14': { edition: '558', date: '2023-09-14', word: 'LUTAM'},
  '2023-09-15': { edition: '559', date: '2023-09-15', word: 'PULSA'},
  '2023-09-16': { edition: '560', date: '2023-09-16', word: 'QUARO', meaning: 'QUARÓ: arbusto ( Thryallis brasiliensis ) da fam. das malpighiáceas, com flores amarelo-avermelhadas, em racemos, e cápsulas aculeadas, nativo do Brasil (PA a MG e ao RS), cultivado como ornamental e por propriedades adstringentes, tônicas, catárticas e febrífugas; guaco, jasmim-amarelo, nó-de-cachorro, resedá-amarelo, tintureira, tintureiro.' },
  '2023-09-17': { edition: '561', date: '2023-09-17', word: 'BUTIO', meaning: 'BÚTIO: nas minas, conduto cilíndrico que leva o ar aos foles.' },
  '2023-09-18': { edition: '562', date: '2023-09-18', word: 'CABOS'},
  '2023-09-19': { edition: '563', date: '2023-09-19', word: 'FACES'},
  '2023-09-20': { edition: '564', date: '2023-09-20', word: 'BONZA', meaning: 'monja budista.' },
  '2023-09-21': { edition: '565', date: '2023-09-21', word: 'INCAS'},
  '2023-09-22': { edition: '566', date: '2023-09-22', word: 'LESIM', meaning: 'fenda, rachadura na madeira.' },
  '2023-09-23': { edition: '567', date: '2023-09-23', word: 'FULAS', meaning: 'povo muçulmano que vive disseminado pela África ocidental, do lago Tchad à costa atlântica, esp. no Níger, Nigéria, Máli, Guiné e Camarões.' },
  '2023-09-24': { edition: '568', date: '2023-09-24', word: 'XAMPU', meaning: 'solução saponácea us. para lavar os cabelos e o couro cabeludo.' },
  '2023-09-25': { edition: '569', date: '2023-09-25', word: 'SONAR'},
  '2023-09-26': { edition: '570', date: '2023-09-26', word: 'UMERO', meaning: 'osso do braço que se articula, na parte inferior (face distal), com o rádio e a ulna, e, na parte superior (face proximal), com a escápula.' },
  '2023-09-27': { edition: '571', date: '2023-09-27', word: 'DIGNA'},
  '2023-09-28': { edition: '572', date: '2023-09-28', word: 'GRAFO', meaning: 'conjunto cujos elementos são unidos por arcos.' },
  '2023-09-29': { edition: '573', date: '2023-09-29', word: 'AJUDE'},
  '2023-09-30': { edition: '574', date: '2023-09-30', word: 'TRAMO'},
  '2023-10-01': { edition: '575', date: '2023-10-01', word: 'BUDAS'},
  '2023-10-02': { edition: '576', date: '2023-10-02', word: 'TRUPE'},
  '2023-10-03': { edition: '577', date: '2023-10-03', word: 'BRIZA'},
  '2023-10-04': { edition: '578', date: '2023-10-04', word: 'PEDIR'},
  '2023-10-05': { edition: '579', date: '2023-10-05', word: 'MOLHA'},
  '2023-10-06': { edition: '580', date: '2023-10-06', word: 'CANIL'},
  '2023-10-07': { edition: '581', date: '2023-10-07', word: 'MEADO', meaning: 'que ou o que está no meio ou aproximadamente pela metade (freq. us. no pl., como subst.). "m. setembro, chegaram as chuvas da primavera"' },
  '2023-10-08': { edition: '582', date: '2023-10-08', word: 'SOGRA'},
  '2023-10-09': { edition: '583', date: '2023-10-09', word: 'DELTA'},
  '2023-10-10': { edition: '584', date: '2023-10-10', word: 'PRATO'},
  '2023-10-11': { edition: '585', date: '2023-10-11', word: 'NICHO'},
  '2023-10-12': { edition: '586', date: '2023-10-12', word: 'SUJAM'},
  '2023-10-13': { edition: '587', date: '2023-10-13', word: 'BIPAR'},
  '2023-10-14': { edition: '588', date: '2023-10-14', word: 'LEITA', meaning: 'esperma de peixe, substância branca e semifluida, leitosa na consistência e no aspecto; ova.' },
  '2023-10-15': { edition: '589', date: '2023-10-15', word: 'BATOM'},
  '2023-10-16': { edition: '590', date: '2023-10-16', word: 'VOLTA'},
  '2023-10-17': { edition: '591', date: '2023-10-17', word: 'BISAR', meaning: 'pedir, esp. com gritos de bis, que seja repetido (aquilo que se acaba de ouvir ou de presenciar, esp. num espetáculo, função, audição, concerto etc.).' },
  '2023-10-18': { edition: '592', date: '2023-10-18', word: 'BORAL', meaning: 'tipo de alumínio que contém 35% de pó de carbureto de boro [É laminado em pranchas, recoberto de alumínio de ambos os lados e utiliza-se em blindagens térmicas por ter uma seção de choque de absorção de nêutrons térmicos elevada.].' },
  '2023-10-19': { edition: '593', date: '2023-10-19', word: 'LUDIO', meaning: 'LÚDIO: Figurinha, que flutua numa garrafa cheia de água, e que serve para demonstrar a teoria da aerostação.' },
  '2023-10-20': { edition: '594', date: '2023-10-20', word: 'CALMO'},
  '2023-10-21': { edition: '595', date: '2023-10-21', word: 'GOSTA'},
  '2023-10-22': { edition: '596', date: '2023-10-22', word: 'AUTOR'},
  '2023-10-23': { edition: '597', date: '2023-10-23', word: 'MOSCA'},
  '2023-10-24': { edition: '598', date: '2023-10-24', word: 'MIUDO', meaning: 'MIÚDO: de tamanho reduzido; pequeno.' },
  '2023-10-25': { edition: '599', date: '2023-10-25', word: 'BUCHA'},
  '2023-10-26': { edition: '600', date: '2023-10-26', word: 'RUGAS'},
  '2023-10-27': { edition: '601', date: '2023-10-27', word: 'CHINA'},
  '2023-10-28': { edition: '602', date: '2023-10-28', word: 'BANDO'},
  '2023-10-29': { edition: '603', date: '2023-10-29', word: 'ATRIZ'},
  '2023-10-30': { edition: '604', date: '2023-10-30', word: 'SABER'},
  '2023-10-31': { edition: '605', date: '2023-10-31', word: 'PROVA'},
  '2023-11-01': { edition: '606', date: '2023-11-01', word: 'BAILE'},
  '2023-11-02': { edition: '607', date: '2023-11-02', word: 'FREVO'},
  '2023-11-03': { edition: '608', date: '2023-11-03', word: 'COXAS'},
  '2023-11-04': { edition: '609', date: '2023-11-04', word: 'REFIL'},
  '2023-11-05': { edition: '610', date: '2023-11-05', word: 'TENDA'},
  '2023-11-06': { edition: '611', date: '2023-11-06', word: 'GRILO'},
  '2023-11-07': { edition: '612', date: '2023-11-07', word: 'LITRO'},
  '2023-11-08': { edition: '613', date: '2023-11-08', word: 'ARDIL', meaning: 'ação que se vale de astúcia, manha, sagacidade; ardileza.'},
  '2023-11-09': { edition: '614', date: '2023-11-09', word: 'SEXAR', meaning: 'determinar o sexo (esp. em aves), por meio de técnicas apropriadas de sexagem.'},
  '2023-11-10': { edition: '615', date: '2023-11-10', word: 'VOGAL'},
  '2023-11-11': { edition: '616', date: '2023-11-11', word: 'GLEBA'},
  '2023-11-12': { edition: '617', date: '2023-11-12', word: 'TUNDA', meaning: 'o mesmo que SURRA'},
  '2023-11-13': { edition: '618', date: '2023-11-13', word: 'FISGO', meaning: 'parte pontiaguda de anzol ou arpão, pela qual se fisga o peixe; fisga, barbela'},
  '2023-11-14': { edition: '619', date: '2023-11-14', word: 'FLUOR', meaning: 'FLÚOR: elemento químico de número atômico 9, da família dos halogênios (símb.: F )'},
  '2023-11-15': { edition: '620', date: '2023-11-15', word: 'LOCAR'},
  '2023-11-16': { edition: '621', date: '2023-11-16', word: 'HIRCO', meaning: 'm.q. BODE (macho da cabra)'},
  '2023-11-17': { edition: '622', date: '2023-11-17', word: 'OXIMA', meaning: 'classe de compostos que resultam da condensação de aldeído ou cetona com hidroxilamina'},
  '2023-11-18': { edition: '623', date: '2023-11-18', word: 'BRAVO'},
  '2023-11-19': { edition: '624', date: '2023-11-19', word: 'ATROZ', meaning: 'intensamente cruel, desumano'},
  '2023-11-20': { edition: '625', date: '2023-11-20', word: 'SUFLE', meaning: 'SUFLÊ: iguaria leve, salgada ou doce, feita à base de um creme de farinha de trigo ou maisena, do ingrediente principal (legumes, peixe, queijo, chocolate etc.), e de claras em neve, e que é servida na própria vasilha em que foi levada ao forno'},
  '2023-11-21': { edition: '626', date: '2023-11-21', word: 'CLONE'},
  '2023-11-22': { edition: '627', date: '2023-11-22', word: 'JUDAS'},
  '2023-11-23': { edition: '628', date: '2023-11-23', word: 'DRUSA', meaning: 'massa globular formada por cristais em forma de agulha, encontrada fixa à parede celular ou livre no citoplasma'},
  '2023-11-24': { edition: '629', date: '2023-11-24', word: 'REIXA', meaning: 'intenso sentimento de raiva; ira, ódio'},
  '2023-11-25': { edition: '630', date: '2023-11-25', word: 'BOCAL'},
  '2023-11-26': { edition: '631', date: '2023-11-26', word: 'LIMPO'},
  '2023-11-27': { edition: '632', date: '2023-11-27', word: 'FELGA', meaning: 'torrão de terra desfeito.'},
  '2023-11-28': { edition: '633', date: '2023-11-28', word: 'ALTOS'},
  '2023-11-29': { edition: '634', date: '2023-11-29', word: 'PRIMA'},
  '2023-11-30': { edition: '635', date: '2023-11-30', word: 'DUBIO', meaning: 'DÚBIO: sujeito a diferentes interpretações; ambíguo.'},
  '2023-12-01': { edition: '636', date: '2023-12-01', word: 'BOIAR'},
  '2023-12-02': { edition: '637', date: '2023-12-02', word: 'TRAJE'},
  '2023-12-03': { edition: '638', date: '2023-12-03', word: 'FURIA', meaning: 'FÚRIA: exaltação violenta de ânimo; ira, raiva, cólera.'},
  '2023-12-04': { edition: '639', date: '2023-12-04', word: 'VIOLA'},
  '2023-12-05': { edition: '640', date: '2023-12-05', word: 'SURFA'},
  '2023-12-06': { edition: '641', date: '2023-12-06', word: 'MIURA', meaning: 'MIÚRA: touro bravo, difícil de lidar.'},
  '2023-12-07': { edition: '642', date: '2023-12-07', word: 'CUSPE'},
  '2023-12-08': { edition: '643', date: '2023-12-08', word: 'PINOS'},
  '2023-12-09': { edition: '644', date: '2023-12-09', word: 'SACHE', meaning: 'SACHÊ: Pequeno saco ou pequena almofada em que se deitam perfumes ou substâncias medicamentosas.'},
  '2023-12-10': { edition: '645', date: '2023-12-10', word: 'VISAO', meaning: 'VISÃO'},
  '2023-12-11': { edition: '646', date: '2023-12-11', word: 'LIDOU'},
  '2023-12-12': { edition: '647', date: '2023-12-12', word: 'FEIRA'},
  '2023-12-13': { edition: '648', date: '2023-12-13', word: 'BURLA'},
  '2023-12-14': { edition: '649', date: '2023-12-14', word: 'HOSTE', meaning: 'inimigo, adversário.'},
  '2023-12-15': { edition: '650', date: '2023-12-15', word: 'VAMOS'},
  '2023-12-16': { edition: '651', date: '2023-12-16', word: 'EXITA'},
  '2023-12-17': { edition: '652', date: '2023-12-17', word: 'CAMPO'},
  '2023-12-18': { edition: '653', date: '2023-12-18', word: 'GRIFA'},
  '2023-12-19': { edition: '654', date: '2023-12-19', word: 'TUMOR'},
  '2023-12-20': { edition: '655', date: '2023-12-20', word: 'CEIFA', meaning: 'ação ou efeito de ceifar; aceifa, sega.'},
  '2023-12-21': { edition: '656', date: '2023-12-21', word: 'FLORA'},
  '2023-12-22': { edition: '657', date: '2023-12-22', word: 'BACIO', meaning: 'recipiente em que se recolhem excrementos humanos; penico, urinol.'},
  '2023-12-23': { edition: '658', date: '2023-12-23', word: 'MULTA'},
  '2023-12-24': { edition: '659', date: '2023-12-24', word: 'FRITO'},
  '2023-12-25': { edition: '660', date: '2023-12-25', word: 'VENTO'},
  '2023-12-26': { edition: '661', date: '2023-12-26', word: 'NOVAS'},
  '2023-12-27': { edition: '662', date: '2023-12-27', word: 'BENTA'},
  '2023-12-28': { edition: '663', date: '2023-12-28', word: 'VACUO', meaning: 'VÁCUO'},
  '2023-12-29': { edition: '664', date: '2023-12-29', word: 'BICAS'},
  '2023-12-30': { edition: '665', date: '2023-12-30', word: 'REDIL', meaning: 'curral para gado, esp. ovino ou caprino; aprisco.'},
  '2023-12-31': { edition: '666', date: '2023-12-31', word: 'SEITA'},
  '2024-01-01': { edition: '667', date: '2024-01-01', word: 'VESPA'},
  '2024-01-02': { edition: '668', date: '2024-01-02', word: 'EVITA'},
  '2024-01-03': { edition: '669', date: '2024-01-03', word: 'VILAO', meaning: 'VILÃO'},
  '2024-01-04': { edition: '670', date: '2024-01-04', word: 'GRATO'},
  '2024-01-05': { edition: '671', date: '2024-01-05', word: 'MAGRO'},
  '2024-01-06': { edition: '672', date: '2024-01-06', word: 'XINGU'},
  '2024-01-07': { edition: '673', date: '2024-01-07', word: 'CEIAM'},
  '2024-01-08': { edition: '674', date: '2024-01-08', word: 'BATEM'},
  '2024-01-09': { edition: '675', date: '2024-01-09', word: 'ERGUI'},
  '2024-01-10': { edition: '676', date: '2024-01-10', word: 'RESMA', meaning: 'conjunto formado por quinhentas folhas de papel'},
  '2024-01-11': { edition: '677', date: '2024-01-11', word: 'MARTE'},
  '2024-01-12': { edition: '678', date: '2024-01-12', word: 'POMAR'},
  '2024-01-13': { edition: '679', date: '2024-01-13', word: 'COBRE'},
  '2024-01-14': { edition: '680', date: '2024-01-14', word: 'LAUDO'},
  '2024-01-15': { edition: '681', date: '2024-01-15', word: 'ROCAM'},
  '2024-01-16': { edition: '682', date: '2024-01-16', word: 'LIVRO'},
  '2024-01-17': { edition: '683', date: '2024-01-17', word: 'LENTO'},
  '2024-01-18': { edition: '684', date: '2024-01-18', word: 'GRITO'},
  '2024-01-19': { edition: '685', date: '2024-01-19', word: 'VISGO', meaning: 'm.q. VISCOSIDADE'},
  '2024-01-20': { edition: '686', date: '2024-01-20', word: 'SENTA'},
  '2024-01-21': { edition: '687', date: '2024-01-21', word: 'MALHO'},
  '2024-01-22': { edition: '688', date: '2024-01-22', word: 'FOLIA'},
  '2024-01-23': { edition: '689', date: '2024-01-23', word: 'RELVA'},
  '2024-01-24': { edition: '690', date: '2024-01-24', word: 'GRUDE'},
  '2024-01-25': { edition: '691', date: '2024-01-25', word: 'NOIVA'},
  '2024-01-26': { edition: '692', date: '2024-01-26', word: 'ILHAR'},
  '2024-01-27': { edition: '693', date: '2024-01-27', word: 'MURAL'},
  '2024-01-28': { edition: '694', date: '2024-01-28', word: 'SUINO', meaning: 'SUÍNO'},
  '2024-01-29': { edition: '695', date: '2024-01-29', word: 'PUTAS'},
  '2024-01-30': { edition: '696', date: '2024-01-30', word: 'TIROS'},
  '2024-01-31': { edition: '697', date: '2024-01-31', word: 'TEDIO', meaning: 'TÉDIO'},
  '2024-02-01': { edition: '698', date: '2024-02-01', word: 'OCUPA'},
  '2024-02-02': { edition: '699', date: '2024-02-02', word: 'TRAIU'},
  '2024-02-03': { edition: '700', date: '2024-02-03', word: 'CHIBA', meaning: 'm.q. CABRITA.'},
  '2024-02-04': { edition: '701', date: '2024-02-04', word: 'REPTO', meaning: 'ato ou efeito de reptar, de opor-se.'},
  '2024-02-05': { edition: '702', date: '2024-02-05', word: 'TURCA'},
  '2024-02-06': { edition: '703', date: '2024-02-06', word: 'DIZER'},
  '2024-02-07': { edition: '704', date: '2024-02-07', word: 'BURCA'},
  '2024-02-08': { edition: '705', date: '2024-02-08', word: 'ILUDE'},
  '2024-02-09': { edition: '706', date: '2024-02-09', word: 'CILHA', meaning: 'cinta larga, de couro ou de tecido reforçado, que cinge a barriga das cavalgaduras para apertar a sela ou a carga.'},
  '2024-02-10': { edition: '707', date: '2024-02-10', word: 'RISTE', meaning: 'suporte de ferro que tinha por finalidade firmar o 3conto ("parte inferior") da lança, quando o cavaleiro estava pronto para investir.'},
  '2024-02-11': { edition: '708', date: '2024-02-11', word: 'CUNHA'},
  '2024-02-12': { edition: '709', date: '2024-02-12', word: 'FESTA'},
  '2024-02-13': { edition: '710', date: '2024-02-13', word: 'ALFIR'},
  '2024-02-14': { edition: '711', date: '2024-02-14', word: 'HORTA'},
  '2024-02-15': { edition: '712', date: '2024-02-15', word: 'BERNO'},
  '2024-02-16': { edition: '713', date: '2024-02-16', word: 'FUTRE', meaning: 'indivíduo desprezível, reles; bandalho, futrica.'},
  '2024-02-17': { edition: '714', date: '2024-02-17', word: 'MEIOS'},
  '2024-02-18': { edition: '715', date: '2024-02-18', word: 'CIRPO', meaning: 'design. comum às plantas do gên. Scirpus, da fam. das ciperáceas, que reúne cerca de 20 spp., de distribuição subcosmopolita; escirpo.'},
  '2024-02-19': { edition: '716', date: '2024-02-19', word: 'LOBAS'},
  '2024-02-20': { edition: '717', date: '2024-02-20', word: 'CETRO', meaning: 'bastão de comando próprio da autoridade real.'},
  '2024-02-21': { edition: '718', date: '2024-02-21', word: 'GRUTA'},
  '2024-02-22': { edition: '719', date: '2024-02-22', word: 'TURNO'},
  '2024-02-23': { edition: '720', date: '2024-02-23', word: 'CHEGA'},
  '2024-02-24': { edition: '721', date: '2024-02-24', word: 'PRAZO'},
  '2024-02-25': { edition: '722', date: '2024-02-25', word: 'FELPO'},
  '2024-02-26': { edition: '723', date: '2024-02-26', word: 'BOTAS'},
  '2024-02-27': { edition: '724', date: '2024-02-27', word: 'CRUPE', meaning: 'obstrução aguda da laringe devida a infecção, alergia, corpo estranho ou tumor, que provoca tosse, rouquidão e pode levar à asfixia'},
  '2024-02-28': { edition: '725', date: '2024-02-28', word: 'POTRA'},
  '2024-02-29': { edition: '726', date: '2024-02-29', word: 'SULCO'},
  '2024-03-01': { edition: '727', date: '2024-03-01', word: 'DEPOR'},
  '2024-03-02': { edition: '728', date: '2024-03-02', word: 'FITAR'},
  '2024-03-03': { edition: '729', date: '2024-03-03', word: 'MEIGO'},
  '2024-03-04': { edition: '730', date: '2024-03-04', word: 'UREIA'},
  '2024-03-05': { edition: '731', date: '2024-03-05', word: 'CURTO'},
  '2024-03-06': { edition: '732', date: '2024-03-06', word: 'PRIMO'},
  '2024-03-07': { edition: '733', date: '2024-03-07', word: 'NORTE'},
  '2024-03-08': { edition: '734', date: '2024-03-08', word: 'LIDAR'},
  '2024-03-09': { edition: '735', date: '2024-03-09', word: 'LAZER'},
  '2024-03-10': { edition: '736', date: '2024-03-10', word: 'PONTE'},
  '2024-03-11': { edition: '737', date: '2024-03-11', word: 'LENHA'},
  '2024-03-12': { edition: '738', date: '2024-03-12', word: 'ORGIA'},
  '2024-03-13': { edition: '739', date: '2024-03-13', word: 'VESGA'},
  '2024-03-14': { edition: '740', date: '2024-03-14', word: 'GRIFO', meaning: 'animal fabuloso, com cabeça, bico e asas de águia e corpo de leão [Possui dupla natureza: divina, representada pelo espaço aéreo, próprio da águia, e terrestre, representada pelo leão. Tais animais simbolizam, ainda, respectivamente, a sabedoria e a força.]'},
  '2024-03-15': { edition: '741', date: '2024-03-15', word: 'CLIPE'},
  '2024-03-16': { edition: '742', date: '2024-03-16', word: 'BLITZ'},
  '2024-03-17': { edition: '743', date: '2024-03-17', word: 'PORCA'},
  '2024-03-18': { edition: '744', date: '2024-03-18', word: 'USINA'},
  '2024-03-19': { edition: '745', date: '2024-03-19', word: 'RITMO'},
  '2024-03-20': { edition: '746', date: '2024-03-20', word: 'POEMA'},
  '2024-03-21': { edition: '747', date: '2024-03-21', word: 'SURDO'},
  '2024-03-22': { edition: '748', date: '2024-03-22', word: 'PUBAR', meaning: 'amolecer e fermentar (a mandioca).'},
  '2024-03-23': { edition: '749', date: '2024-03-23', word: 'VERBO'},
  '2024-03-24': { edition: '750', date: '2024-03-24', word: 'JUGAR'},
  '2024-03-25': { edition: '751', date: '2024-03-25', word: 'BARES'},
  '2024-03-26': { edition: '752', date: '2024-03-26', word: 'FUMAR'},
  '2024-03-27': { edition: '753', date: '2024-03-27', word: 'FOSCA'},
  '2024-03-28': { edition: '754', date: '2024-03-28', word: 'PUNHO'},
  '2024-03-29': { edition: '755', date: '2024-03-29', word: 'SEPTO'},
  '2024-03-30': { edition: '756', date: '2024-03-30', word: 'PISCA'},
  '2024-03-31': { edition: '757', date: '2024-03-31', word: 'NAIPE'},
  '2024-04-01': { edition: '758', date: '2024-04-01', word: 'LESMA'},
  '2024-04-02': { edition: '759', date: '2024-04-02', word: 'MIXAR', meaning: 'fazer a mixagem de (sinais de som ou de imagem), misturando e combinando várias entradas de som'},
  '2024-04-03': { edition: '760', date: '2024-04-03', word: 'LINCE', meaning: 'design. comum a três spp. de mamíferos do gên. Felis, da fam. dos felídeos, com cauda curta ou muito curta e tufos de pelos nas orelhas'},
  '2024-04-04': { edition: '761', date: '2024-04-04', word: 'LINUX'},
  '2024-04-05': { edition: '762', date: '2024-04-05', word: 'TERMO'},
  '2024-04-06': { edition: '763', date: '2024-04-06', word: 'TEXAS'},
  '2024-04-07': { edition: '764', date: '2024-04-07', word: 'LIVOR', meaning: 'm.q. LIVIDEZ'},
  '2024-04-08': { edition: '765', date: '2024-04-08', word: 'CANOS'},
  '2024-04-09': { edition: '766', date: '2024-04-09', word: 'GENIO', meaning: 'GÊNIO: conjunto dos traços psíquicos e fisiológicos que moldam o temperamento e o humor de cada pessoa'},
  '2024-04-10': { edition: '767', date: '2024-04-10', word: 'COZER', meaning: 'm.q. COZINHAR (nas cinco primeiras acp.)'},
  '2024-04-11': { edition: '768', date: '2024-04-11', word: 'DOCIL', meaning: 'DÓCIL'},
  '2024-04-12': { edition: '769', date: '2024-04-12', word: 'MUDAR'},
  '2024-04-13': { edition: '770', date: '2024-04-13', word: 'DURMO'},
  '2024-04-14': { edition: '771', date: '2024-04-14', word: 'LUGAR'},
  '2024-04-15': { edition: '772', date: '2024-04-15', word: 'SETOR'},
  '2024-04-16': { edition: '773', date: '2024-04-16', word: 'VELHA'},
  '2024-04-17': { edition: '774', date: '2024-04-17', word: 'BAFIO', meaning: 'cheiro peculiar ao que é ou está úmido ou privado de renovação do ar; bolor, mofo'},
  '2024-04-18': { edition: '775', date: '2024-04-18', word: 'PASMO'},
  '2024-04-19': { edition: '776', date: '2024-04-19', word: 'POSTA'},
  '2024-04-20': { edition: '777', date: '2024-04-20', word: 'SUJAR'},
  '2024-04-21': { edition: '778', date: '2024-04-21', word: 'MILHO'},
  '2024-04-22': { edition: '779', date: '2024-04-22', word: 'PLENO'},
  '2024-04-23': { edition: '780', date: '2024-04-23', word: 'MISTO'},
  '2024-04-24': { edition: '781', date: '2024-04-24', word: 'DOGMA'},
  '2024-04-25': { edition: '782', date: '2024-04-25', word: 'SOLDA'},
  '2024-04-26': { edition: '783', date: '2024-04-26', word: 'CORJA'},
  '2024-04-27': { edition: '784', date: '2024-04-27', word: 'GARFO'},
  '2024-04-28': { edition: '785', date: '2024-04-28', word: 'FERVO', meaning: 'grande desentendimento ou briga, confusão, desordem ruidosa'},
  '2024-04-29': { edition: '786', date: '2024-04-29', word: 'CUSTO'},
  '2024-04-30': { edition: '787', date: '2024-04-30', word: 'CULPA'},
  '2024-05-01': { edition: '788', date: '2024-05-01', word: 'CABER'},
  '2024-05-02': { edition: '789', date: '2024-05-02', word: 'DUCHA'},
  '2024-05-03': { edition: '790', date: '2024-05-03', word: 'NOBEL'},
  '2024-05-04': { edition: '791', date: '2024-05-04', word: 'SIGNA', meaning: 'sinal distintivo; bandeira, estandarte, insígnia, pendão, sina'},
  '2024-05-05': { edition: '792', date: '2024-05-05', word: 'MINAS'},
  '2024-05-06': { edition: '793', date: '2024-05-06', word: 'RUSTO', meaning: 'ação ou efeito de rustir; enruste'},
  '2024-05-07': { edition: '794', date: '2024-05-07', word: 'LUPIA', meaning: 'm.q. LOBINHO (cisto sebáceo)'},
  '2024-05-08': { edition: '795', date: '2024-05-08', word: 'TRAPO'},
  '2024-05-09': { edition: '796', date: '2024-05-09', word: 'FEDOR', meaning: 'cheiro repugnante, nauseabundo; fedentina, fedorentina'},
  '2024-05-10': { edition: '797', date: '2024-05-10', word: 'DITAR'},
  '2024-05-11': { edition: '798', date: '2024-05-11', word: 'MITRA', meaning: 'chapéu alto, com uma fenda na parte superior e duas fitas que caem sobre as espáduas, us. pelo Papa, pelos bispos, arcebispos e cardeais'},
  '2024-05-12': { edition: '799', date: '2024-05-12', word: 'CALDO'},
  '2024-05-13': { edition: '800', date: '2024-05-13', word: 'CHINO', meaning: 'm.q. CHINÊS (relativo a ou natural)'},
  '2024-05-14': { edition: '801', date: '2024-05-14', word: 'PARVO', meaning: 'diz-se de, ou indivíduo tolo, pouco inteligente, apoucado, atoleimado'},
  '2024-05-15': { edition: '802', date: '2024-05-15', word: 'TERMA', meaning: 'menos us. que TERMAS'},
  '2024-05-16': { edition: '803', date: '2024-05-16', word: 'EXTRA'},
  '2024-05-17': { edition: '804', date: '2024-05-17', word: 'RENDA'},
  '2024-05-18': { edition: '805', date: '2024-05-18', word: 'VADIO'},
  '2024-05-19': { edition: '806', date: '2024-05-19', word: 'FUNDE'},
  '2024-05-20': { edition: '807', date: '2024-05-20', word: 'ORCAS'},
  '2024-05-21': { edition: '808', date: '2024-05-21', word: 'PARTO'},
  '2024-05-22': { edition: '809', date: '2024-05-22', word: 'CAQUI'},
  '2024-05-23': { edition: '810', date: '2024-05-23', word: 'SERVA'},
  '2024-05-24': { edition: '811', date: '2024-05-24', word: 'DORME'},
  '2024-05-25': { edition: '812', date: '2024-05-25', word: 'ROLAS'},
  '2024-05-26': { edition: '813', date: '2024-05-26', word: 'LINDO'},
  '2024-05-27': { edition: '814', date: '2024-05-27', word: 'POSAR'},
  '2024-05-28': { edition: '815', date: '2024-05-28', word: 'BILHA', meaning: 'vaso bojudo e de gargalo estreito, ger. de barro, us. para água, leite, vinho e outros líquidos potáveis; moringa'},
  '2024-05-29': { edition: '816', date: '2024-05-29', word: 'LEVOU'},
  '2024-05-30': { edition: '817', date: '2024-05-30', word: 'SUADO'},
  '2024-05-31': { edition: '818', date: '2024-05-31', word: 'TOQUE'},
  '2024-06-01': { edition: '819', date: '2024-06-01', word: 'FODER'},
  '2024-06-02': { edition: '820', date: '2024-06-02', word: 'LIXOU'},
  '2024-06-03': { edition: '821', date: '2024-06-03', word: 'RUIVO'},
  '2024-06-04': { edition: '822', date: '2024-06-04', word: 'MAIOR'},
  '2024-06-05': { edition: '823', date: '2024-06-05', word: 'BICOU'},
  '2024-06-06': { edition: '824', date: '2024-06-06', word: 'SALMO'},
  '2024-06-07': { edition: '825', date: '2024-06-07', word: 'CONES'},
  '2024-06-08': { edition: '826', date: '2024-06-08', word: 'AFEGO'},
  '2024-06-09': { edition: '827', date: '2024-06-09', word: 'PECOU'},
  '2024-06-10': { edition: '828', date: '2024-06-10', word: 'CREDO'},
  '2024-06-11': { edition: '829', date: '2024-06-11', word: 'NUDEZ'},
  '2024-06-12': { edition: '830', date: '2024-06-12', word: 'SARGO', meaning: 'm.q. MARIMBÁ' },
  '2024-06-13': { edition: '831', date: '2024-06-13', word: 'PINEL'},
  '2024-06-14': { edition: '832', date: '2024-06-14', word: 'VIGAS'},
  '2024-06-15': { edition: '833', date: '2024-06-15', word: 'OLHEM'},
  '2024-06-16': { edition: '834', date: '2024-06-16', word: 'CORAL'},
  '2024-06-17': { edition: '835', date: '2024-06-17', word: 'LUXOS'},
  '2024-06-18': { edition: '836', date: '2024-06-18', word: 'OBESA'},
  '2024-06-19': { edition: '837', date: '2024-06-19', word: 'ROUCA'},
  '2024-06-20': { edition: '838', date: '2024-06-20', word: 'RONCA'},
  '2024-06-21': { edition: '839', date: '2024-06-21', word: 'CALOU'},
  '2024-06-22': { edition: '840', date: '2024-06-22', word: 'DORES'},
  '2024-06-23': { edition: '841', date: '2024-06-23', word: 'PUNGA', meaning: 'espécie de samba cantado, marcado por tambor, com versos improvisados e que é dançado em roda, ger. sobre terra batida; ponga, tambor de crioula.' },
  '2024-06-24': { edition: '842', date: '2024-06-24', word: 'PESCO'},
  '2024-06-25': { edition: '843', date: '2024-06-25', word: 'FUGIR'},
  '2024-06-26': { edition: '844', date: '2024-06-26', word: 'TALHO'},
  '2024-06-27': { edition: '845', date: '2024-06-27', word: 'ZIMBO', meaning: 'm.q. JIMBO (pequena concha)'},
  '2024-06-28': { edition: '846', date: '2024-06-28', word: 'BRUTO'},
  '2024-06-29': { edition: '847', date: '2024-06-29', word: 'LIDER'},
  '2024-06-30': { edition: '848', date: '2024-06-30', word: 'GELAR'},
  '2024-07-01': { edition: '849', date: '2024-07-01', word: 'VOCAL'},
  '2024-07-02': { edition: '850', date: '2024-07-02', word: 'CELHA', meaning: 'm.q. CÍLIO.'},
  '2024-07-03': { edition: '851', date: '2024-07-03', word: 'FANHO'},
  '2024-07-04': { edition: '852', date: '2024-07-04', word: 'CUREI'},
  '2024-07-05': { edition: '853', date: '2024-07-05', word: 'VOTAM'},
  '2024-07-06': { edition: '854', date: '2024-07-06', word: 'BOCHA', meaning: 'jogo praticado com diversas bolas grandes e uma pequena (bolim), todas de madeira ou de plástico denso'},
  '2024-07-07': { edition: '855', date: '2024-07-07', word: 'PITAR'},
  '2024-07-08': { edition: '856', date: '2024-07-08', word: 'PEIDO'},
  '2024-07-09': { edition: '857', date: '2024-07-09', word: 'BINGA', meaning: 'm.q. CORNO (apêndice ósseo)'},
  '2024-07-10': { edition: '858', date: '2024-07-10', word: 'FORUM', meaning: 'FÓRUM'},
  '2024-07-11': { edition: '859', date: '2024-07-11', word: 'MOEDA'},
  '2024-07-12': { edition: '860', date: '2024-07-12', word: 'APELO'},
  '2024-07-13': { edition: '861', date: '2024-07-13', word: 'PUDOR'},
  '2024-07-14': { edition: '862', date: '2024-07-14', word: 'FASOR', meaning: 'diz-se de ou vetor us. para representar uma grandeza sinusoidal; seu comprimento representa a amplitude da grandeza, enquanto que a fase da mesma é representada pelo ângulo que o vetor faz com alguma direção fixa escolhida como referência; se a grandeza representada varia no tempo, sua variação se expressa pela rotação do fasor'},
  '2024-07-15': { edition: '863', date: '2024-07-15', word: 'ORDEM'},
  '2024-07-16': { edition: '864', date: '2024-07-16', word: 'PIELA', meaning: 'estado de quem se embriagou; bebedeira'},
  '2024-07-17': { edition: '865', date: '2024-07-17', word: 'RUMPI', meaning: 'o atabaque de porte médio, dos três ger. utilizados nos candomblés.'},
  '2024-07-18': { edition: '866', date: '2024-07-18', word: 'BUREL', meaning: 'tecido grosseiro de lã, ger. parda, marrom ou preta, us. na vestimenta de alguns religiosos e penitentes'},
  '2024-07-19': { edition: '867', date: '2024-07-19', word: 'GATIL', meaning: 'lugar onde se criam, hospedam ou comercializam gatos'},
  '2024-07-20': { edition: '868', date: '2024-07-20', word: 'CHUTE'},
  '2024-07-21': { edition: '869', date: '2024-07-21', word: 'DEUSA'},
  '2024-07-22': { edition: '870', date: '2024-07-22', word: 'VIROU'},
  '2024-07-23': { edition: '871', date: '2024-07-23', word: 'MAGOS'},
  '2024-07-24': { edition: '872', date: '2024-07-24', word: 'NEPAL'},
  '2024-07-25': { edition: '873', date: '2024-07-25', word: 'GROTA', meaning: 'cavidade, na encosta de serra ou de morro, provocada por águas das chuvas, ou, em ribanceira de rio, por águas de enchentes'},
  '2024-07-26': { edition: '874', date: '2024-07-26', word: 'SAFOU'},
  '2024-07-27': { edition: '875', date: '2024-07-27', word: 'TULHA', meaning: 'cova ou local de pedra onde se coloca e comprime a azeitona antes de passar para o lagar'},
  '2024-07-28': { edition: '876', date: '2024-07-28', word: 'POSTE'},
  '2024-07-29': { edition: '877', date: '2024-07-29', word: 'FRITA'},
  '2024-07-30': { edition: '878', date: '2024-07-30', word: 'BALDO', meaning: 'desprovido (de algo); carente, falho'},
  '2024-07-31': { edition: '879', date: '2024-07-31', word: 'MIJAR'},
  '2024-08-01': { edition: '880', date: '2024-08-01', word: 'MACHO'},
  '2024-08-02': { edition: '881', date: '2024-08-02', word: 'HIATO', meaning: 'grupo de duas vogais contíguas que pertencem a sílabas diferentes (p.ex.: aí, frio, saúde )'},
  '2024-08-03': { edition: '882', date: '2024-08-03', word: 'MACIO'},
  '2024-08-04': { edition: '883', date: '2024-08-04', word: 'OPTAR'},
  '2024-08-05': { edition: '884', date: '2024-08-05', word: 'CASTO', meaning: 'que guarda castidade; que se abstém de prazeres sexuais'},
  '2024-08-06': { edition: '885', date: '2024-08-06', word: 'HIENA'},
  '2024-08-07': { edition: '886', date: '2024-08-07', word: 'BANIU'},
  '2024-08-08': { edition: '887', date: '2024-08-08', word: 'EXIDO'},
  '2024-08-09': { edition: '888', date: '2024-08-09', word: 'SUITE', meaning: 'Suíte'},
  '2024-08-10': { edition: '889', date: '2024-08-10', word: 'CRINA'},
  '2024-08-11': { edition: '890', date: '2024-08-11', word: 'LIXAR'},
  '2024-08-12': { edition: '891', date: '2024-08-12', word: 'OLHAR'},
  '2024-08-13': { edition: '892', date: '2024-08-13', word: 'PATOS'},
  '2024-08-14': { edition: '893', date: '2024-08-14', word: 'TOMAR'},
  '2024-08-15': { edition: '894', date: '2024-08-15', word: 'RINDO'},
  '2024-08-16': { edition: '895', date: '2024-08-16', word: 'BIFES'},
  '2024-08-17': { edition: '896', date: '2024-08-17', word: 'BISAO', meaning: 'Bisão: design. comum aos mamíferos artiodáctilos do gên. Bison, da fam. dos bovídeos, encontrados na América do Norte e Europa; de pelagem longa sobre o quarto anterior do corpo e cabeça grande, com chifres curtos e robustos'},
  '2024-08-18': { edition: '897', date: '2024-08-18', word: 'LAPSO'},
  '2024-08-19': { edition: '898', date: '2024-08-19', word: 'COTIM', meaning: 'tecido de linho ou algodão, formado de fios muito finos; cotil'},
  '2024-08-20': { edition: '899', date: '2024-08-20', word: 'LAPIS', meaning: 'LÁPIS'},
  '2024-08-21': { edition: '900', date: '2024-08-21', word: 'REGUA', meaning: 'RÉGUA'},
  '2024-08-22': { edition: '901', date: '2024-08-22', word: 'SUAVE'},
  '2024-08-23': { edition: '902', date: '2024-08-23', word: 'NETOS'},
  '2024-08-24': { edition: '903', date: '2024-08-24', word: 'NORMA'},
  '2024-08-25': { edition: '904', date: '2024-08-25', word: 'PRADO', meaning: 'm.q. CAMPINA (formação)'},
  '2024-08-26': { edition: '905', date: '2024-08-26', word: 'VIRGA', meaning: 'vara flexível; verga'},
  '2024-08-27': { edition: '906', date: '2024-08-27', word: 'MORSA'},
  '2024-08-28': { edition: '907', date: '2024-08-28', word: 'MUFLA', meaning: 'quadro central dos interruptores em instalações elétricas'},
  '2024-08-29': { edition: '908', date: '2024-08-29', word: 'FAUNO', meaning: 'divindade campestre (metade homem, metade animal) com pés de cabra e cornos; vivia nos bosques e protegia os rebanhos'},
  '2024-08-30': { edition: '909', date: '2024-08-30', word: 'GARBO', meaning: 'elegância de modos, de gestos; donaire, galhardia'},
  '2024-08-31': { edition: '910', date: '2024-08-31', word: 'NARDO', meaning: 'design. comum às plantas do gên. Nardus, da fam. das gramíneas, com apenas uma sp., nativa da Europa e Oeste da Ásia, tb. conhecida como bácaro'},
  '2024-09-01': { edition: '911', date: '2024-09-01', word: 'MODAL', meaning: 'relativo a modo ou modalidade'},
  '2024-09-02': { edition: '912', date: '2024-09-02', word: 'VALOR'},
  '2024-09-03': { edition: '913', date: '2024-09-03', word: 'PENAS'},
  '2024-09-04': { edition: '914', date: '2024-09-04', word: 'ENGAR', meaning: 'criar (o animal) hábito de pastagem em determinado local'},
  '2024-09-05': { edition: '915', date: '2024-09-05', word: 'JULGA'},
  '2024-09-06': { edition: '916', date: '2024-09-06', word: 'SOFRI'},
  '2024-09-07': { edition: '917', date: '2024-09-07', word: 'PACTO'},
  '2024-09-08': { edition: '918', date: '2024-09-08', word: 'PODAR'},
  '2024-09-09': { edition: '919', date: '2024-09-09', word: 'TELHA'},
  '2024-09-10': { edition: '920', date: '2024-09-10', word: 'PLUMA'},
  '2024-09-11': { edition: '921', date: '2024-09-11', word: 'BOLHA'},
  '2024-09-12': { edition: '922', date: '2024-09-12', word: 'FUGAZ', meaning: 'que tem rapidez; rápido, ligeiro, veloz'},
  '2024-09-13': { edition: '923', date: '2024-09-13', word: 'PERSA'},
  '2024-09-14': { edition: '924', date: '2024-09-14', word: 'UNTAR'},
  '2024-09-15': { edition: '925', date: '2024-09-15', word: 'BULHA', meaning: 'ruído ou gritaria de uma ou mais pessoas'},
  '2024-09-16': { edition: '926', date: '2024-09-16', word: 'GOIAS', meaning: 'GOIÁS'},
  '2024-09-17': { edition: '927', date: '2024-09-17', word: 'BOLAS'},
  '2024-09-18': { edition: '928', date: '2024-09-18', word: 'MELRO', meaning: 'ave passeriforme ( Turdus merula ) da fam. dos muscicapídeos, encontrada na Europa, Ásia e Norte da África; o macho é inteiramente negro, exceto pelo bico e pelo anel ao redor dos olhos, que são alaranjados; melro-preto'},
  '2024-09-19': { edition: '929', date: '2024-09-19', word: 'IBERO', meaning: 'relativo à península Ibérica; ibérico'},
  '2024-09-20': { edition: '930', date: '2024-09-20', word: 'RADIO', meaning: 'RÁDIO'},
  '2024-09-21': { edition: '931', date: '2024-09-21', word: 'SUGAR'},
  '2024-09-22': { edition: '932', date: '2024-09-22', word: 'GOIVA', meaning: 'ferramenta de seção côncavo-convexa, com o corte do lado côncavo, utilizada por artesãos e artistas para talhar os contornos de peças de madeira, metal ou pedra'},
  '2024-09-23': { edition: '933', date: '2024-09-23', word: 'VENDA'},
  '2024-09-24': { edition: '934', date: '2024-09-24', word: 'LUFAR', meaning: 'soprar fortemente (o vento)'},
  '2024-09-25': { edition: '935', date: '2024-09-25', word: 'TENIS', meaning: 'TÊNIS'},
  '2024-09-26': { edition: '936', date: '2024-09-26', word: 'HELIO', meaning: 'HÉLIO: elemento químico de número atômico 2, da família dos gases nobres (símb.: He )'},
  '2024-09-27': { edition: '937', date: '2024-09-27', word: 'FIXAR', meaning: 'prender ou colar (algo) [em]; cravar, pregar'},
  '2024-09-28': { edition: '938', date: '2024-09-28', word: 'PICAR'},
  '2024-09-29': { edition: '939', date: '2024-09-29', word: 'UIVAR'},
  '2024-09-30': { edition: '940', date: '2024-09-30', word: 'MOLHE'},
  '2024-10-01': { edition: '941', date: '2024-10-01', word: 'VENAL', meaning: 'relativo a venda'},
  '2024-10-02': { edition: '942', date: '2024-10-02', word: 'FRUTO'},
  '2024-10-03': { edition: '943', date: '2024-10-03', word: 'GLENA', meaning: 'cavidade de um osso na qual se articula outro'},
  '2024-10-04': { edition: '944', date: '2024-10-04', word: 'ILUSO', meaning: 'que foi iludido; enganado, logrado'},
  '2024-10-05': { edition: '945', date: '2024-10-05', word: 'GLOSA', meaning: 'supressão total ou parcial de uma quantia averbada num escrito'},
  '2024-10-06': { edition: '946', date: '2024-10-06', word: 'FORTE'},
  '2024-10-07': { edition: '947', date: '2024-10-07', word: 'MUROS'},
  '2024-10-08': { edition: '948', date: '2024-10-08', word: 'ANJOS'},
  '2024-10-09': { edition: '949', date: '2024-10-09', word: 'LIMAR'},
  '2024-10-10': { edition: '950', date: '2024-10-10', word: 'FLATO', meaning: 'm.q. FLATULÊNCIA'},
  '2024-10-11': { edition: '951', date: '2024-10-11', word: 'BUSTO'},
  '2024-10-12': { edition: '952', date: '2024-10-12', word: 'COVAL', meaning: 'parte de um cemitério destinada à abertura de covas'},
  '2024-10-13': { edition: '953', date: '2024-10-13', word: 'FICAR'},
  '2024-10-14': { edition: '954', date: '2024-10-14', word: 'TAXOU'},
  '2024-10-15': { edition: '955', date: '2024-10-15', word: 'GRUDA'},
  '2024-10-16': { edition: '956', date: '2024-10-16', word: 'SACRO', meaning: 'relativo ao que é divino, à religião, aos rituais e ao culto; sagrado, santo'},
  '2024-10-17': { edition: '957', date: '2024-10-17', word: 'FELIZ'},
  '2024-10-18': { edition: '958', date: '2024-10-18', word: 'SUPRA', meaning: 'acima [Us. para indicar trecho da mesma página, mais acima, ou de página(s) anterior(es).]'},
  '2024-10-19': { edition: '959', date: '2024-10-19', word: 'MANHO', meaning: 'sem orientação; que não sabe o que fazer ou dizer; desnorteado, atrapalhado, pateta'},
  '2024-10-20': { edition: '960', date: '2024-10-20', word: 'RESTO'},
  '2024-10-21': { edition: '961', date: '2024-10-21', word: 'JUIZO', meaning: 'JUÍZO'},
  '2024-10-22': { edition: '962', date: '2024-10-22', word: 'DOTAR'},
  '2024-10-23': { edition: '963', date: '2024-10-23', word: 'PENOL', meaning: 'extremidade de pau da carangueja ou pau de carga oposto ao ponto de articulação; pena, pique'},
  '2024-10-24': { edition: '964', date: '2024-10-24', word: 'COPLA', meaning: 'poesia popular espanhola, com estâncias curtas e métrica variável, ger. cantada com acompanhamento de música improvisada'},
  '2024-10-25': { edition: '965', date: '2024-10-25', word: 'DOPAR'},
  '2024-10-26': { edition: '966', date: '2024-10-26', word: 'VENTA'},
  '2024-10-27': { edition: '967', date: '2024-10-27', word: 'CERTO'},
  '2024-10-28': { edition: '968', date: '2024-10-28', word: 'TIRSO', meaning: 'bastão enfeitado com hera e pâmpanos, e rematado em forma de pinha, atributo de Baco e das bacantes, personagens da mitologia greco-romana'},
  '2024-10-29': { edition: '969', date: '2024-10-29', word: 'PREGO'},
  '2024-10-30': { edition: '970', date: '2024-10-30', word: 'CENSO', meaning: 'Pesquisa realizada periodicamente para calcular número de pessoas de um país, de um grupo social etc.'},
  '2024-10-31': { edition: '971', date: '2024-10-31', word: 'POETA'},
  '2024-11-01': { edition: '972', date: '2024-11-01', word: 'SALTO'},
  '2024-11-02': { edition: '973', date: '2024-11-02', word: 'PLENA'},
  '2024-11-03': { edition: '974', date: '2024-11-03', word: 'SUBIR'},
  '2024-11-04': { edition: '975', date: '2024-11-04', word: 'GAZIL', meaning: 'que prima pela elegância na aparência, nas maneiras; elegante, airoso'},
  '2024-11-05': { edition: '976', date: '2024-11-05', word: 'PUJAR', meaning: 'm.q. LUTAR (bater-se, despender esforços)'},
  '2024-11-06': { edition: '977', date: '2024-11-06', word: 'MIGAR', meaning: 'transformar em migas'},
  '2024-11-07': { edition: '978', date: '2024-11-07', word: 'LAUTO', meaning: 'que é feito ou apresentado de forma abundante, copiosa; opíparo, magnífico, esplêndido'},
  '2024-11-08': { edition: '979', date: '2024-11-08', word: 'FRACO'},
  '2024-11-09': { edition: '980', date: '2024-11-09', word: 'OBTER'},
  '2024-11-10': { edition: '981', date: '2024-11-10', word: 'MUDAS'},
  '2024-11-11': { edition: '982', date: '2024-11-11', word: 'LADOS'},
  '2024-11-12': { edition: '983', date: '2024-11-12', word: 'ADEJO', meaning: 'agitação semelhante ao bater de asas; abano'},
  '2024-11-13': { edition: '984', date: '2024-11-13', word: 'IRADO'},
  '2024-11-14': { edition: '985', date: '2024-11-14', word: 'MUITA'},
  '2024-11-15': { edition: '986', date: '2024-11-15', word: 'GORJA', meaning: 'garganta, goela'},
  '2024-11-16': { edition: '987', date: '2024-11-16', word: 'FAVOR'},
  '2024-11-17': { edition: '988', date: '2024-11-17', word: 'DICAS'},
  '2024-11-18': { edition: '989', date: '2024-11-18', word: 'TEMOR'},
  '2024-11-19': { edition: '990', date: '2024-11-19', word: 'CHILE'},
  '2024-11-20': { edition: '991', date: '2024-11-20', word: 'APURO'},
  '2024-11-21': { edition: '992', date: '2024-11-21', word: 'PORTE'},
  '2024-11-22': { edition: '993', date: '2024-11-22', word: 'SONDA'},
  '2024-11-23': { edition: '994', date: '2024-11-23', word: 'AGUDE', meaning: 'inseto us. pelos passarinheiros como isca para apanhar aves; agúdia'},
  '2024-11-24': { edition: '995', date: '2024-11-24', word: 'TAROL', meaning: 'pequena caixa-clara que se percute com duas baquetas'},
  '2024-11-25': { edition: '996', date: '2024-11-25', word: 'CERDO', meaning: 'm.q. PORCO ( Sus scrofa )'},
  '2024-11-26': { edition: '997', date: '2024-11-26', word: 'LOMBA', meaning: 'dorso ou crista arredondada de colina, serra, monte, montanha'},
  '2024-11-27': { edition: '998', date: '2024-11-27', word: 'TUNEL', meaning: 'TÚNEL'},
  '2024-11-28': { edition: '999', date: '2024-11-28', word: 'ANUIR', meaning: 'consentir (com gestos ou palavras); estar de acordo; aprovar, assentir'},
  '2024-11-29': { edition: '1000', date: '2024-11-29', word: 'MAGNO', meaning: 'que pela importância se sobrepõe a tudo que lhe é congênere; de grande relevância'},
  '2024-11-30': { edition: '1001', date: '2024-11-30', word: 'TICAR'},
  '2024-12-01': { edition: '1002', date: '2024-12-01', word: 'CISNE'},
  '2024-12-02': { edition: '1003', date: '2024-12-02', word: 'RUMBA'},
  '2024-12-03': { edition: '1004', date: '2024-12-03', word: 'ALETO', meaning: 'em falcoaria, aves de rapina de pequeno porte, velozes e hábeis caçadoras de perdizes e outras aves'},
  '2024-12-04': { edition: '1005', date: '2024-12-04', word: 'FIRME'},
  '2024-12-05': { edition: '1006', date: '2024-12-05', word: 'MUITO'},
  '2024-12-06': { edition: '1007', date: '2024-12-06', word: 'ADEUS'},
  '2024-12-07': { edition: '1008', date: '2024-12-07', word: 'DENSO'},
  '2024-12-08': { edition: '1009', date: '2024-12-08', word: 'SABRE'},
  '2024-12-09': { edition: '1010', date: '2024-12-09', word: 'ELUIR', meaning:'provocar a eluição de; fracionar (uma mistura de partículas)'},
  '2024-12-10': { edition: '1011', date: '2024-12-10', word: 'CHATO'},
  '2024-12-11': { edition: '1012', date: '2024-12-11', word: 'MINOU'},
  '2024-12-12': { edition: '1013', date: '2024-12-12', word: 'PRELO', meaning: 'aparelho manual ou mecânico que serve para imprimir'},
  '2024-12-13': { edition: '1014', date: '2024-12-13', word: 'ROBLE', meaning: '1. arbusto ou árvore ( Fagus antarctica ) da fam. das fagáceas, nativa da Terra do Fogo e cultivada como ornamental. - 2. m.q. CARVALHO ( Quercus robur )'},
  '2024-12-14': { edition: '1015', date: '2024-12-14', word: 'RONDA'},
  '2024-12-15': { edition: '1016', date: '2024-12-15', word: 'BOIAS'},
  '2024-12-16': { edition: '1017', date: '2024-12-16', word: 'VEADO'},
  '2024-12-17': { edition: '1018', date: '2024-12-17', word: 'SELOU'},
  '2024-12-18': { edition: '1019', date: '2024-12-18', word: 'SENDA'},
  '2024-12-19': { edition: '1020', date: '2024-12-19', word: 'COVIL'},
  '2024-12-20': { edition: '1021', date: '2024-12-20', word: 'ADESO', meaning: 'm.q. ADERIDO'},
  '2024-12-21': { edition: '1022', date: '2024-12-21', word: 'GESTO'},
  '2024-12-22': { edition: '1023', date: '2024-12-22', word: 'RENGO', meaning: 'fio com que se tece a cassa'},
  '2024-12-23': { edition: '1024', date: '2024-12-23', word: 'DOCES'},
  '2024-12-24': { edition: '1025', date: '2024-12-24', word: 'ORIXA', meaning: 'ORIXÁ'},
  '2024-12-25': { edition: '1026', date: '2024-12-25', word: 'MUNIR'},
  '2024-12-26': { edition: '1027', date: '2024-12-26', word: 'APEGO'},
  '2024-12-27': { edition: '1028', date: '2024-12-27', word: 'RECTO'},
  '2024-12-28': { edition: '1029', date: '2024-12-28', word: 'TORPE', meaning: 'que contraria ou fere os bons costumes, a decência, a moral; que revela caráter vil; ignóbil, indecoroso, infame'},
  '2024-12-29': { edition: '1030', date: '2024-12-29', word: 'LUNAR'},
  '2024-12-30': { edition: '1031', date: '2024-12-30', word: 'PRETA'},
  '2024-12-31': { edition: '1032', date: '2024-12-31', word: 'VILTA', meaning: 'palavra ou ação que avilta, ofende; injúria, insulto, vitupério'},
  '2025-01-01': { edition: '1033', date: '2025-01-01', word: 'CAROS'},
  '2025-01-02': { edition: '1034', date: '2025-01-02', word: 'MACRO'},
  '2025-01-03': { edition: '1035', date: '2025-01-03', word: 'CUPIM'},
  '2025-01-04': { edition: '1036', date: '2025-01-04', word: 'TANCO'},
  '2025-01-05': { edition: '1037', date: '2025-01-05', word: 'GUIAS'},
  '2025-01-06': { edition: '1038', date: '2025-01-06', word: 'MOFAR'},
  '2025-01-07': { edition: '1039', date: '2025-01-07', word: 'ROTAS'},
  '2025-01-08': { edition: '1040', date: '2025-01-08', word: 'FENDA'},
  '2025-01-09': { edition: '1041', date: '2025-01-09', word: 'SINAL'},
  '2025-01-10': { edition: '1042', date: '2025-01-10', word: 'PETAR', meaning: 'dizer petas, mentiras'},
  '2025-01-11': { edition: '1043', date: '2025-01-11', word: 'FOCAL'},
  '2025-01-12': { edition: '1044', date: '2025-01-12', word: 'PSILO'},
  '2025-01-13': { edition: '1045', date: '2025-01-13', word: 'HAVER'},
  '2025-01-14': { edition: '1046', date: '2025-01-14', word: 'TELAS'},
  '2025-01-15': { edition: '1047', date: '2025-01-15', word: 'TANSO'},
  '2025-01-16': { edition: '1048', date: '2025-01-16', word: 'CRINO'},
  '2025-01-17': { edition: '1049', date: '2025-01-17', word: 'FLUXO'},
  '2025-01-18': { edition: '1050', date: '2025-01-18', word: 'PARDO'},
  '2025-01-19': { edition: '1051', date: '2025-01-19', word: 'DITOS'},
  '2025-01-20': { edition: '1052', date: '2025-01-20', word: 'CARIL'},
  '2025-01-21': { edition: '1053', date: '2025-01-21', word: 'CENTO'},
  '2025-01-22': { edition: '1054', date: '2025-01-22', word: 'MISTA'},
  '2025-01-23': { edition: '1055', date: '2025-01-23', word: 'JOIAS'},
  '2025-01-24': { edition: '1056', date: '2025-01-24', word: 'ANEXO'},
  '2025-01-25': { edition: '1057', date: '2025-01-25', word: 'LEPRA'},
  '2025-01-26': { edition: '1058', date: '2025-01-26', word: 'LUCRA'},
  '2025-01-27': { edition: '1059', date: '2025-01-27', word: 'VODCA'},
  '2025-01-28': { edition: '1060', date: '2025-01-28', word: 'SURFO'},
  '2025-01-29': { edition: '1061', date: '2025-01-29', word: 'BERTA', meaning: 'canhão de 42 cm us. pelos alemães na Primeira Guerra Mundial para bombardear Paris de 122 km de distância'},
  '2025-01-30': { edition: '1062', date: '2025-01-30', word: 'VAGIR', meaning: 'chorar, lamentar-se, lamuriar-se ou produzir som como o de um vagido'},
  '2025-01-31': { edition: '1063', date: '2025-01-31', word: 'TARDO', meaning: 'que tudo faz sem pressa; tardinheiro, tardonho'},
  '2025-02-01': { edition: '1064', date: '2025-02-01', word: 'BAQUE'},
  '2025-02-02': { edition: '1065', date: '2025-02-02', word: 'MORTE'},
  '2025-02-03': { edition: '1066', date: '2025-02-03', word: 'LIGAM'},
  '2025-02-04': { edition: '1067', date: '2025-02-04', word: 'NEVAR'},
  '2025-02-05': { edition: '1068', date: '2025-02-05', word: ''},
  '2025-02-06': { edition: '1069', date: '2025-02-06', word: ''},
  '2025-02-07': { edition: '1070', date: '2025-02-07', word: ''},
  '2025-02-08': { edition: '1071', date: '2025-02-08', word: ''},
  '2025-02-09': { edition: '1072', date: '2025-02-09', word: ''},
  '2025-02-10': { edition: '1073', date: '2025-02-10', word: ''},
  '2025-02-11': { edition: '1074', date: '2025-02-11', word: ''},
  '2025-02-12': { edition: '1075', date: '2025-02-12', word: ''},
  '2025-02-13': { edition: '1076', date: '2025-02-13', word: ''},
  '2025-02-14': { edition: '1077', date: '2025-02-14', word: ''},
  '2025-02-15': { edition: '1078', date: '2025-02-15', word: ''},
  '2025-02-16': { edition: '1079', date: '2025-02-16', word: ''},
  '2025-02-17': { edition: '1080', date: '2025-02-17', word: ''},
  '2025-02-18': { edition: '1081', date: '2025-02-18', word: ''},
  '2025-02-19': { edition: '1082', date: '2025-02-19', word: ''},
  '2025-02-20': { edition: '1083', date: '2025-02-20', word: ''},
  '2025-02-21': { edition: '1084', date: '2025-02-21', word: ''},
  '2025-02-22': { edition: '1085', date: '2025-02-22', word: ''},
  '2025-02-23': { edition: '1086', date: '2025-02-23', word: ''},
  '2025-02-24': { edition: '1087', date: '2025-02-24', word: ''},
  '2025-02-25': { edition: '1088', date: '2025-02-25', word: ''},
  '2025-02-26': { edition: '1089', date: '2025-02-26', word: ''},
  '2025-02-27': { edition: '1090', date: '2025-02-27', word: ''},
  '2025-02-28': { edition: '1091', date: '2025-02-28', word: ''},
  '2025-03-01': { edition: '1092', date: '2025-03-01', word: ''},
  '2025-03-02': { edition: '1093', date: '2025-03-02', word: ''},
  '2025-03-03': { edition: '1094', date: '2025-03-03', word: ''},
  '2025-03-04': { edition: '1095', date: '2025-03-04', word: ''},
  '2025-03-05': { edition: '1096', date: '2025-03-05', word: ''},
  '2025-03-06': { edition: '1097', date: '2025-03-06', word: ''},
  '2025-03-07': { edition: '1098', date: '2025-03-07', word: ''},
  '2025-03-08': { edition: '1099', date: '2025-03-08', word: ''},
  '2025-03-09': { edition: '1100', date: '2025-03-09', word: ''},
  '2025-03-10': { edition: '1101', date: '2025-03-10', word: ''},
  '2025-03-11': { edition: '1102', date: '2025-03-11', word: ''},
  '2025-03-12': { edition: '1103', date: '2025-03-12', word: ''},
  '2025-03-13': { edition: '1104', date: '2025-03-13', word: ''},
  '2025-03-14': { edition: '1105', date: '2025-03-14', word: ''},
  '2025-03-15': { edition: '1106', date: '2025-03-15', word: ''},
  '2025-03-16': { edition: '1107', date: '2025-03-16', word: ''},
  '2025-03-17': { edition: '1108', date: '2025-03-17', word: ''},
  '2025-03-18': { edition: '1109', date: '2025-03-18', word: ''},
  '2025-03-19': { edition: '1110', date: '2025-03-19', word: ''},
  '2025-03-20': { edition: '1111', date: '2025-03-20', word: ''},
  '2025-03-21': { edition: '1112', date: '2025-03-21', word: ''},
  '2025-03-22': { edition: '1113', date: '2025-03-22', word: ''},
  '2025-03-23': { edition: '1114', date: '2025-03-23', word: ''},
  '2025-03-24': { edition: '1115', date: '2025-03-24', word: ''},
  '2025-03-25': { edition: '1116', date: '2025-03-25', word: ''},
  '2025-03-26': { edition: '1117', date: '2025-03-26', word: ''},
  '2025-03-27': { edition: '1118', date: '2025-03-27', word: ''},
  '2025-03-28': { edition: '1119', date: '2025-03-28', word: ''},
  '2025-03-29': { edition: '1120', date: '2025-03-29', word: ''},
  '2025-03-30': { edition: '1121', date: '2025-03-30', word: ''},
  '2025-03-31': { edition: '1122', date: '2025-03-31', word: ''},
  '2025-04-01': { edition: '1123', date: '2025-04-01', word: ''},
  '2025-04-02': { edition: '1124', date: '2025-04-02', word: ''},
  '2025-04-03': { edition: '1125', date: '2025-04-03', word: ''},
  '2025-04-04': { edition: '1126', date: '2025-04-04', word: ''},
  '2025-04-05': { edition: '1127', date: '2025-04-05', word: ''},
  '2025-04-06': { edition: '1128', date: '2025-04-06', word: ''},
  '2025-04-07': { edition: '1129', date: '2025-04-07', word: ''},
  '2025-04-08': { edition: '1130', date: '2025-04-08', word: ''},
  '2025-04-09': { edition: '1131', date: '2025-04-09', word: ''},
  '2025-04-10': { edition: '1132', date: '2025-04-10', word: ''},
  '2025-04-11': { edition: '1133', date: '2025-04-11', word: ''},
  '2025-04-12': { edition: '1134', date: '2025-04-12', word: ''},
  '2025-04-13': { edition: '1135', date: '2025-04-13', word: ''},
  '2025-04-14': { edition: '1136', date: '2025-04-14', word: ''},
  '2025-04-15': { edition: '1137', date: '2025-04-15', word: ''},
  '2025-04-16': { edition: '1138', date: '2025-04-16', word: ''},
  '2025-04-17': { edition: '1139', date: '2025-04-17', word: ''},



}



// const word = '';
// const startEdition = 451;
// const startDate = new Date('2023-05-31');
// const numDays = 120;

// let currentEdition = startEdition;
// let currentDate = startDate;

// const output = [];

// for (let i = 0; i < numDays; i++) {
//   const dateString = currentDate.toISOString().split('T')[0];
//   const entry = `  '${dateString}': { edition: '${currentEdition}', date: '${dateString}', word: '${word}' }`;
//   output.push(entry);
  
//   currentEdition++;
//   currentDate.setDate(currentDate.getDate() + 1);
// }

// console.log(output.join(',\n'));